<div class="toolbarheader">
    USERS LIST
   </div>
   <br/>

<div class="search-users">
    <input style="height:20px;font-size:14px;margin-top:2px;" type="text" placeholder="Search Here" [(ngModel)]="searchText">
    <span class="spacer"></span>
    <span style="text-align:right;"><b>{{ member_count }}</b> Active Members
    <br/> <b>{{ user_count }}</b> Total Users</span>
  </div>




<button style="color:green;" mat-stroked-button (click)="do_show_member_list()">Active</button>
<button color="warn" mat-stroked-button (click)="do_show_inactive_list()">Inactive</button>
<button mat-stroked-button (click)="do_show_user_list()">ALL</button>
<br/><br/>
<mat-divider></mat-divider>
<div *ngIf="show_user_list">
  <div *ngFor="let site_user of user_list | search: searchText" >
  <table routerLink="/admin-user-detail/{{ site_user['_id'] }}">
      <tr>
        <th rowspan="3">
      <mat-icon class="status-icon-active" *ngIf="site_user['member']">person</mat-icon>
      <mat-icon class="status-icon-inactive" *ngIf="!site_user['member']">close</mat-icon>
        </th>
        <th rowspan="3" *ngIf="site_user['picture']"><img class="circle-image" src="{{site_user['picture']}}" alt="picture"/>
        </th>
        <th rowspan="3" *ngIf="!site_user['picture']"><img class="circle-image" src="/assets/images/graysquare.jpg" alt="graysquare">
        </th>
        <td matListItemTitle> <b>{{site_user['title']}}</b>
        </td>
      </tr>
      <tr>
        <i>{{site_user['emailaddress']}}</i>
      </tr>
      <tr>
        <td>{{site_user['description']}}
        </td>
      </tr>
    </table>
  </div>
</div>

<div *ngIf="show_member_list"><span style="color:green;font-weight:bold;">Active</span>
<div *ngFor="let site_user of member_list | search: searchText">
  <table routerLink="/admin-user-detail/{{ site_user['_id'] }}">
      <tr>
        <th rowspan="3">
      <mat-icon class="status-icon-active" *ngIf="site_user['member']">person</mat-icon>
      <mat-icon class="status-icon-inactive" *ngIf="!site_user['member']">close</mat-icon>
        </th>
        <th rowspan="3" *ngIf="site_user['picture']"><img class="circle-image" src="{{site_user['picture']}}" alt="picture"/>
        </th>
        <th rowspan="3" *ngIf="!site_user['picture']"><img class="circle-image" src="/assets/images/graysquare.jpg" alt="graysquare">
        </th>
        <td matListItemTitle> <b>{{site_user['title']}}</b>
        </td>
      </tr>
      <tr>
        <i>{{site_user['emailaddress']}}</i>
      </tr>
      <tr>
        <td>{{site_user['description']}}
        </td>
      </tr>
    </table>
  </div>
</div>

<div *ngIf="show_inactive_list"><span style="color:red;font-weight:bold;">Inactive</span>
  <div *ngFor="let site_user of inactive_list | search: searchText">
    <table routerLink="/admin-user-detail/{{ site_user['_id'] }}">
        <tr>
          <th rowspan="3">
        <mat-icon class="status-icon-active" *ngIf="site_user['member']">person</mat-icon>
        <mat-icon class="status-icon-inactive" *ngIf="!site_user['member']">close</mat-icon>
          </th>
          <th rowspan="3" *ngIf="site_user['picture']"><img class="circle-image" src="{{site_user['picture']}}" alt="picture"/>
          </th>
          <th rowspan="3" *ngIf="!site_user['picture']"><img class="circle-image" src="/assets/images/graysquare.jpg" alt="graysquare">
          </th>
          <td matListItemTitle> <b>{{site_user['title']}}</b>
          </td>
        </tr>
        <tr>
          <i>{{site_user['emailaddress']}}</i>
        </tr>
        <tr>
          <td>{{site_user['description']}}
          </td>
        </tr>
      </table>
    </div>
  </div>