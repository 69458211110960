import { Component } from '@angular/core';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent {
  constructor(public lightningService: LightningService) { }
  slugify_url ="index";
  title = "";
  html_safe_text = "";
  header_image = "";

  public page = {};

  // load the page into the form 
  ngOnInit(): void {
    console.log('homepage init');
    
      this.getPageData();

  }

  getPageData = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/pages/' + this.slugify_url);
    const result = await response.json();
    console.log(result);
    console.log(result.page); 
    if (result.page) {
      console.log('found page');
      console.log(result.page['title'])
      this.page = result.page;
      this.title = result.page['title']
      this.html_safe_text = result.page['html_safe_text']
      this.header_image = result.page['header_image']
    }
  }

}
