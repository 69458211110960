
<div class="toolbarheader" >
  CREATE A NEW SEMESTER
</div>
<br/>
<br/>

<form #semesterCreateForm="ngForm">
  <mat-form-field>
    <mat-label for="title">Title (Year & Season)</mat-label>
    <input matInput id="title" type="text" [formControl]="title">
  </mat-form-field>
<br/>

  <mat-form-field class="example-form-field">
    <mat-label>Semester Duration</mat-label>
    <mat-date-range-input
      [formGroup]="courseDuration"
      [rangePicker]="courseDurationPicker"
      [comparisonStart]="courseDuration.value.starts"
      [comparisonEnd]="courseDuration.value.ends">
      <input matStartDate placeholder="Start date" formControlName="starts">
      <input matEndDate placeholder="End date" formControlName="ends">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="courseDurationPicker"></mat-datepicker-toggle>
    <mat-date-range-picker #courseDurationPicker></mat-date-range-picker>
  </mat-form-field>
  <br/><br/>

  <mat-form-field class="example-full-width">
    <mat-label for="description">Admin Notes </mat-label>
    <textarea matInput id="description" type="text" [formControl]="description"></textarea>
  </mat-form-field>

  <br/>
  <br/>
  
  
</form>

<button mat-raised-button color="primary" type="button" (click)="createSemester()">Create</button>

<br/>