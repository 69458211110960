
<div class="toolbarheader">COURSES
  <span class="spacer"></span>
  <button *ngIf="lightningService.role.update_pages" class="button-style" routerLink="/admin-page-update/courses"><mat-icon>edit</mat-icon></button>
</div>
<img src="{{ header_image }}" alt="banner" width="100%">
<br/><br/>

<div [innerHtml]="html_safe_text | safe: 'html'"></div>

<div class="graybar">{{ semester['title'] }} COURSES 
</div>
<mat-accordion *ngFor="let course of courses">
  <mat-expansion-panel>
    <mat-expansion-panel-header [collapsedHeight]="'108px'">
      <mat-panel-title class="courselist">

    <span class="course-title">#{{ course['order'] }}: {{ course['title'] }}
  </span>
  <span class="category-style"><i>{{ course['subject_category'] }}</i></span>
<span class="facilitator-style">{{ course['facilitator_name'] }}</span>


      </mat-panel-title>

    </mat-expansion-panel-header>
      <p>
        <span>{{ course['notes'] }} 
        </span>
        Capacity: {{ course['capacity'] }} &nbsp;&nbsp;|&nbsp;&nbsp; Signed Up: {{ course['signups'] }}  <br/>
        <ng-container *ngIf="course['fees']"><b>Fee:</b> ${{ course['fees'] }}</ng-container><ng-container *ngIf="course['fees_description']"> --- <b>Fee Details:</b> {{ course['fees_description'] }}</ng-container>

       </p>
      
      <span><img class="courselistimage" src="{{ course['photo'] }}" alt="course photo"  />
      </span>

      <p>{{ course['description'] }}</p>
      <p><b>Facilitator:</b> {{ course['facilitator_bio'] }}
 
  
   
  </mat-expansion-panel>
</mat-accordion>

