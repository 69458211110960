<h1 mat-dialog-title>
Login to WILL:</h1>
<div *ngIf="signinLightning" mat-dialog-content>

    <div *ngIf="loginmodebrowser">
        <b>There are two alternate high-security methods to login to the WILL website.</b>
        <br/><br/>
        <p>#1. GetAlby is a Chrome plugin that requires a one-time download in order to use its authenticator.
          <br/>  You can download the plugin <a href="https://getalby.com">here</a>.
        </p>
        <button style="background-color:lightgray;" [attr.href]="weblnurl" mat-stroked-button color="primary" >Login with browser</button>
        <br/>
        <br/>
 <mat-divider></mat-divider>
 <br/>

        <p>#2. Scan this QR code with <a href="https://coincharge.io/en/lnurl-for-lightning-wallets/">your favorite lightning wallet</a> (must support lnurl-auth).</p>
            <a class="qr-link" href="">
            <img class="qr" id="qr_image" [src]=qrCode >
          </a>

          <mat-divider></mat-divider>
          <br/>
          <p><b>Or, you can go back to logging in with email by clicking on the blue button below.</b></p>
    </div>

    <div *ngIf="loginmodeEmail">
        <p> Enter your email address to receive a temporary login code.
            <br/>Please use the <b>same email address</b> every time you wish to login to WILL.
        </p>
           
        Email Address: <input type="email"  [(ngModel)]="emailaddress" email style="margin-right:10px;margin-bottom:10px;">
        <button mat-raised-button color="primary" (click)="submitEmailNoLightning()">Submit Email</button>



    </div>
</div>
<div *ngIf="signinEmailValidationStart" mat-dialog-content>
    <p>You are logged in with lightning.  </p>
    <h1 mat-dialog-title>Required: Validate email</h1>
    <p>We require your email address so we can email you with updates.</p>
    <p>We will never sell your email address, or give it away to anyone.</p>
    <p>
        Email address: <input type="email"  [(ngModel)]="emailaddress" email>
    </p>
    <p> {{emailaddress}}</p>
</div>
<div *ngIf="signinEmailValidationCodeStart" mat-dialog-content>
    <p>Please check your email for the 6-digit security code.  </p>

    <p>
    Verification Code: <input type="text"  [(ngModel)]="emailaddressverificationcode" >
    </p>
</div>




<table *ngIf="signinLightning" mat-dialog-actions>
    <tr>

    <td>    <button *ngIf="!loginmodeEmail" mat-raised-button color="primary" (click)="changeLoginMode('email')">Login with Email</button></td>
    <td>  <button  *ngIf="!loginmodebrowser" (click)="changeLoginMode('browser')"><mat-icon matTooltip="Alternate login options currently disabled">bolt</mat-icon></button></td>
    </tr>
</table>


<div *ngIf="signinEmailValidationStart" mat-dialog-content>
    <div class="login-button-row">
        <button mat-raised-button color="primary" (click)="submitEmail()">Submit Email</button>
        <button mat-raised-button color="warn" mat-dialog-close>Close</button>
    </div>
</div>
<div *ngIf="signinEmailValidationCodeStart" mat-dialog-content>
    <div class="login-button-row">
        <button *ngIf="!loginmodeEmail" mat-raised-button color="primary" (click)="validateEmail()">Verify</button>
        <button *ngIf="loginmodeEmail" mat-raised-button color="primary" (click)="validateEmailNoLightning()">Verify</button>
    </div>
</div>

