import { Component } from '@angular/core';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-admin-semesters',
  templateUrl: './admin-semesters.component.html',
  styleUrls: ['./admin-semesters.component.css']
})
export class AdminSemestersComponent {
  constructor(public lightningService: LightningService) { }
  public semester_list = [];

  ngOnInit(): void {
    console.log('admin semester init');
    //this.title.setValue(this.lightningService.user['title']);
    this.getSemesterData();

  }

  getSemesterData = async () => {
  const response = await fetch(this.lightningService.apiUrl + '/semesters/', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    const result = await response.json();
    console.log(result);
    if (result.status == "401")
    {
      this.lightningService.requestLogin();
    }

    this.semester_list = result;

  }

}
