import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';

import { LightningService } from '../lightning.service';
import { GenericResponseDialogComponent } from '../generic-response-dialog/generic-response-dialog.component'
import { Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';

@Component({
  selector: 'app-course-proposal-wizard',
  templateUrl: './course-proposal-wizard.component.html',
  styleUrls: ['./course-proposal-wizard.component.css']
})
export class CourseProposalWizardComponent {
  constructor(public lightningService: LightningService, private router: Router, public dialog: MatDialog) { }

  formsection = 1;
  showSubmitButton = true;
  public changeFormSection(nextFormSection: number) {
    this.formsection = nextFormSection;
  }

  title = new FormControl('', Validators.required);
  order = new FormControl('');
  status_number = new FormControl('');

  description = new FormControl('', Validators.required);
  notes = new FormControl('');
  facilitator_bio = new FormControl('');
  minimum_capacity = new FormControl('');
  fees = new FormControl('');
  fees_description = new FormControl ('');
  special_requests = new FormControl('');
  preferred_schedule_request_text = new FormControl('');
  preferred_schedule_request_text1 = new FormControl('');
  preferred_schedule_request_text2 = new FormControl('');
  preferred_schedule_request_text3 = new FormControl('');
  preferred_schedule_request_text4 = new FormControl('');
  preferred_schedule_compiled = "";
  facilitator_shepherd_requested = new FormControl('');
  capacity = new FormControl('');
  subject_category = new FormControl('');
  photo = new FormControl('');
  av_required = new FormControl('');
  av_checklist = new FormControl('');
  // add av checklkist controls here
  av_zoom = new FormControl('');
  av_powerpoint_pc = new FormControl('');
  av_powerpoint_mac = new FormControl('');
  av_internet = new FormControl('');
  av_videos = new FormControl('');
  av_soundsystem = new FormControl('');
  av_whiteboard = new FormControl('');
  av_flipchart = new FormControl('');
  av_other = new FormControl('');
  av_sessions = new FormControl('');
// add av video source here
  av_streaming = new FormControl('');
  av_vhs = new FormControl('');
  av_flashdrive = new FormControl('');
  av_dvdplayer = new FormControl('');
  av_smartphone = new FormControl('');
  av_bluray = new FormControl('');

  outdoor = new FormControl('');
  outdoor_field_trip = new FormControl('');
  semester_request = new FormControl('');
  location = new FormControl('');
  location_type = new FormControl('');

  createCourse = async () => {
    console.log('create proposal');
    console.log(localStorage.getItem("token"));
    
    this.preferred_schedule_compiled = (this.preferred_schedule_request_text1.value  || "") + " sessions // "+
    (this.preferred_schedule_request_text2.value  || "") + " // "+
    (this.preferred_schedule_request_text3.value  || "") + " // "+
    (this.preferred_schedule_request_text4.value  || "");


    if (this.title.value=="" ||
    this.description.value=="" ||
    this.facilitator_bio.value=="" ||
    this.semester_request.value=="" ||
    this.preferred_schedule_request_text1.value=="" ||
    this.preferred_schedule_request_text2.value=="" ||
    this.preferred_schedule_request_text3.value=="" ||
    this.preferred_schedule_request_text4.value=="" ||
    this.capacity.value=="" ||
    this.minimum_capacity.value=="" ||
    this.special_requests.value==""
    ){
      //alert ('You missed a required field. Please click the red GO BACK button at the bottom of each form page and check your work. An asterisk (*) indicates a required field.');
      this.dialog.open(GenericResponseDialogComponent, {
        data: {
          response_message: 'You missed a required field. Please click the red GO BACK button at the bottom of each form page and check your work. An asterisk (*) indicates a required field.',
          response_title: 'Required Field'
        },
      });
      return;
    }
    this.showSubmitButton = false;
    // combine all of the av checklist controls into a string here.
    var av_checklist_combined = "";
    // check the value first
    if (this.av_zoom.value)
    {
      av_checklist_combined = av_checklist_combined + "Zoom. ";
    }
    if (this.av_powerpoint_pc.value)
    {
      av_checklist_combined = av_checklist_combined + "PowerPoint. "; /// this replaced both PC and MAC options
    }
    if (this.av_powerpoint_mac.value)
    {
      av_checklist_combined = av_checklist_combined + "PowerPoint Mac. "; /// this is not used on the HTML page right now
    }
    if (this.av_internet.value)
    {
      av_checklist_combined = av_checklist_combined + "Internet. ";
    }

    if (this.av_soundsystem.value)
    {
      av_checklist_combined = av_checklist_combined + "Sound System with Mic.";
    }

    if (this.av_whiteboard.value)
    {
      av_checklist_combined = av_checklist_combined + "Whiteboard. ";
    }
    if (this.av_flipchart.value)
    {
      av_checklist_combined = av_checklist_combined + "Flipchart. ";
    }
////////////////////////////////////////////////////////
    if (this.av_videos.value)
    {
      av_checklist_combined = av_checklist_combined + "Video: ";
    }
    if (this.av_streaming.value)
    {
      av_checklist_combined = av_checklist_combined + "Streaming Service. ";
    }
    if (this.av_vhs.value)
    {
      av_checklist_combined = av_checklist_combined + "VHS Video Cassette. ";
    }
    if (this.av_flashdrive.value)
    {
      av_checklist_combined = av_checklist_combined + "Flash Drive. ";
    }
    if (this.av_smartphone.value)
    {
      av_checklist_combined = av_checklist_combined + "Smartphone. ";
    }
    if (this.av_dvdplayer.value)
    {
      av_checklist_combined = av_checklist_combined + "DVD Player. ";
    }
    if (this.av_bluray.value)
    {
      av_checklist_combined = av_checklist_combined + "Blu Ray. ";
    }


    if (this.av_other.value)
    {
      av_checklist_combined = av_checklist_combined + "OTHER: " + this.av_other.value + " ";
    }
    if (this.av_sessions.value)
    {
      av_checklist_combined = av_checklist_combined + "// SESSIONS: " + this.av_sessions.value + " ";
    }
    if (this.av_checklist.value)
    {
      av_checklist_combined = av_checklist_combined + "// COMMENTS: " + this.av_checklist.value;
    }



    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'title':this.title.value,
        'order': 0,
        'status_number': this.status_number.value,
        'description': this.description.value,
        'notes': this.notes.value,
        'facilitator_bio': this.facilitator_bio.value,
        'minimum_capacity': this.minimum_capacity.value,
        'fees': this.fees.value,
        'fees_description': this.fees_description.value,
        'special_requests': this.special_requests.value,
        'preferred_schedule_request_text': this.preferred_schedule_compiled, ///we calculated this above to compile multiple entries into one field
        'facilitator_shepherd_requested': this.facilitator_shepherd_requested.value,
        'capacity': this.capacity.value,
        'subject_category': this.subject_category.value,
        'photo': this.photo.value,
        'av_required': this.av_required.value,
        //'av_checklist': this.av_checklist.value,
        'av_checklist': av_checklist_combined,
        'outdoor': this.outdoor.value,
        'outdoor_field_trip': this.outdoor_field_trip.value,
        'semester_request': this.semester_request.value,
        'location': this.location.value,
        'location_type': this.location_type.value
      
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/courses/create', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        console.log(data);

        this.dialog.open(GenericResponseDialogComponent, {
          data: {
            response_message: 'Thank you! Your course proposal has been submitted. A member of the WILL curriculum committee will reach out to you shortly.',
            response_title: 'Success!'
          },
        });
        this.router.navigate(['' ]);
        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

}
