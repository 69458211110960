
<div class="toolbarheader" >
    ROLES
    <span class="spacer"></span>
    <button *ngIf="lightningService.role.create_roles" class="button-style" routerLink="/admin-roles-create"><mat-icon class="add">add</mat-icon></button>
</div>


  <mat-divider></mat-divider>


<div *ngIf="lightningService.role.view_admin_roles_interface">
<mat-list>
    <mat-nav-list>
    <mat-list-item *ngFor="let site_role of role_list" routerLink="/admin-roles-detail/{{ site_role['_id'] }}">
        <b mat-line class="roletitles">  {{site_role['title']}}</b> --- <i mat-line class="roledescrip">{{site_role['description']}} </i>
    </mat-list-item>
</mat-nav-list>
</mat-list>
</div>

<br/><br/><br/><br/><br/><br/><br/><br/>