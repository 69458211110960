import { Component } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css']
})
export class CourseDetailComponent {
  constructor(private lightningService: LightningService, private route: ActivatedRoute) { }

  courseid ="";
  public course = {};
  course_title = "";
  course_description = ""
  course_photo = "";
  course_notes = "";
  facilitator_bio = "";
  capacity = "";
  subject_category = "";
  facilitator_name = "";
  shepherd_name = "";
  fees = "";
  fees_description = "";
  outdoor = "";
  outdoor_field_trip = "";
  start_date = "";
  end_date = "";
  order = "";
  signups = "";

  // load the role into the form 
  ngOnInit(): void {
    console.log('course detail init');
    
    this.route.paramMap.subscribe((params) => {
      console.log('course data params changed')
      this.courseid = params.get('courseid') || "";
      //this.role = this.productService.getProduct(this.id);
      this.getCourseData();

    });
  }

  getCourseData = async () => {

      const response = await fetch(this.lightningService.apiUrl + '/courses/' + this.courseid, {

      });
      const result = await response.json();
      console.log(result);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
      if (result) {
        console.log('found course');
        this.course = result;
        this.course_title = result['title'];
        this.course_description = result['description'];
        this.course_photo = result['photo'];
        
        this.course_notes = result['notes'];

        this.facilitator_bio = result['facilitator_bio'];
        this.capacity = result['capacity'];
        this.subject_category = result['subject_category'];
        this.facilitator_name = result['facilitator_name'];
        this.shepherd_name = result['shepherd_name'];
        this.fees = result['fees'];
        this.fees_description = result['fees_description'];
        this.outdoor = result['outdoor'];
        this.outdoor_field_trip = result['outdoor_field_trip'];
        this.start_date = result['start_date'];
        this.end_date = result['end_date'];
        this.order = result['order'];
        this.signups = result['signups'];


      }
    
  }

}
