import { Component } from '@angular/core';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-enrollment-list',
  templateUrl: './enrollment-list.component.html',
  styleUrls: ['./enrollment-list.component.css']
})
export class EnrollmentListComponent {
  constructor(public lightningService: LightningService) { }

  coursemembers = [];
  shepherd_courses = [];

  // init
  ngOnInit(): void {
    console.log('enrollment list init');
  
    this.getEnrollmentListData();
    this.getShepherdListData();
     
  }

  getEnrollmentListData = async () => {
    if (localStorage.getItem("token") === null) {
      console.log('enrollment data - no access token');
    }
    else
    {
      const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      const response = await fetch(this.lightningService.apiUrl + '/coursemembers/enrollment_list', settings);
      const result = await response.json();
      console.log(result);
      console.log(result.coursemembers); 
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
      if (result.coursemembers) {
        console.log('found coursemembers');

        this.coursemembers = result.coursemembers;


      }
    }
  }

  getShepherdListData = async () => {
    if (localStorage.getItem("token") === null) {
      console.log('shepherd data - no access token');
    }
    else
    {
      const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      const response = await fetch(this.lightningService.apiUrl + '/activesemester/shepherdcourses', settings);
      const result = await response.json();
      console.log(result);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }

      this.shepherd_courses = result;


      
    }
  }


  

}
