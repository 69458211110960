


<div class="content-margin-will logo-text" role="banner">
<img src="/assets/images/will_logo.png" alt="will circle logo" style="padding: 2px 15px 5px 0;" width="110">
Western Institute for Lifelong Learning 
</div>


<!-- XSmall Small and Medium Toolbar -->
<div class="toolbar" role="banner" *ngIf="isXsmall | async">
  <button mat-button [matMenuTriggerFor]="activity_menu">
   MENU ▼
  </button>
  <mat-menu #activity_menu="matMenu">
    <a mat-menu-item routerLink="/"><b>HOME</b></a>
    <button mat-menu-item routerLink="/news">NEWS</button> 
    <button mat-menu-item routerLink="/pages/about">ABOUT</button>
    <button mat-menu-item routerLink="/course-list">COURSES</button>
    <button mat-menu-item routerLink="/lunchlearn">LUNCH & LEARN</button>
    <button mat-menu-item routerLink="/programs">PROGRAMS</button>
    <button mat-menu-item routerLink="/enthusiastgroups">ENTHUSIAST GROUPS</button>
    <button mat-menu-item routerLink="/pages/membership">MEMBERSHIP</button>
    <button mat-menu-item routerLink="/volunteer">OPPORTUNITIES</button>
    <button mat-menu-item routerLink="/pages/contact">CONTACT</button>
  </mat-menu>

  <div class="spacer"></div>

  <span *ngIf="logoutButtonVisible">

    <span *ngIf="userTitle" class="welcome_name" style="color:#1565c0;" >{{ userTitle }} </span>
    <span routerLink="profile" *ngIf="!userTitle" class="welcome_name2" style="color:#1565c0;" >Logged in! Update Profile </span>

      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="drop down icon">
        <mat-icon style="color:#1565c0;">arrow_drop_down_circle</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="profile">
          <mat-icon>person</mat-icon>
          <span>My Profile</span>
        </button>
        <button  mat-menu-item routerLink="enrollment-list">
          <mat-icon>list</mat-icon>
          <span>My Course List</span>
        </button>
        <button  mat-menu-item routerLink="semester-signup">
          <mat-icon>checklist</mat-icon>
          <span>Course Sign Ups</span>
        </button>
        <button mat-menu-item routerLink="course-proposal-wizard">
          <mat-icon>send</mat-icon>
          <span>Propose a Course</span>
        </button>
        <button mat-menu-item  (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>


        <span *ngIf="lightningService.role.view_admin_interface">
          <button mat-icon-button [matMenuTriggerFor]="adminmenu" aria-label="Example icon-button with a menu">
            <mat-icon style="color:#1565c0;">more_vert</mat-icon>
          </button>
          <mat-menu #adminmenu="matMenu">
            <button *ngIf="lightningService.role.view_admin_semesters" mat-menu-item routerLink="admin-semesters">
              <mat-icon>calendar_month</mat-icon>
              <span>Semesters</span>
            </button>
            <button *ngIf="lightningService.role.view_admin_concepts" mat-menu-item routerLink="admin-courses">
              <mat-icon>lightbulb</mat-icon>
              <span>Concepts</span>
            </button>
            <button *ngIf="lightningService.role.view_admin_users_interface" mat-menu-item routerLink="admin-users">
              <mat-icon>group</mat-icon>
              <span>Users</span>
            </button>
            <button *ngIf="lightningService.role.view_admin_roles_interface" mat-menu-item routerLink="admin-roles">
              <mat-icon>workspaces</mat-icon>
              <span>Roles</span>
            </button>
            <button *ngIf="lightningService.role.view_shepherd_resources" mat-menu-item routerLink="pages/shepherds">
              <mat-icon>groups</mat-icon>
              <span>Shepherds</span>
            </button>
          </mat-menu>
        </span>


      </span>

  <button mat-raised-button *ngIf="loginButtonVisible" (click)="loginChallenge()">Login</button>
</div>

<!-- Small Toolbar -->
<div class="toolbar" role="banner" *ngIf="isSmall | async">
  <a mat-button routerLink="/"><b>HOME</b></a>
  <div class="spacer"></div>
  <button mat-button routerLink="/course-list">COURSES</button>
  <div class="spacer"></div>
  <button mat-button routerLink="/pages/membership">MEMBERSHIP</button>
  <div class="spacer"></div>

  <button mat-button [matMenuTriggerFor]="activity_menu">
    MORE ▼
   </button>
   <mat-menu #activity_menu="matMenu">
    <button mat-menu-item routerLink="/lunchlearn">
      <mat-icon>lunch_dining</mat-icon>
      <span>Lunch & Learn</span>
    </button>
    <button mat-menu-item routerLink="/programs">
      <mat-icon>stars</mat-icon>
      <span>Programs</span>
    </button>
    <button mat-menu-item routerLink="/enthusiastgroups">
      <mat-icon>casino</mat-icon>
      <span>Enthusiast Groups</span>
    </button>
     <button mat-menu-item routerLink="/pages/about">ABOUT</button>
     <button mat-menu-item routerLink="/news">NEWS</button> 
     <button mat-menu-item routerLink="/volunteer">OPPORTUNITIES</button>
     <button mat-menu-item routerLink="/pages/contact">CONTACT</button>
   </mat-menu>
   <div class="spacer"></div>

  <span *ngIf="logoutButtonVisible">

    <span *ngIf="userTitle" class="welcome_name" style="color:#1565c0;" >{{ userTitle }} </span>
    <span routerLink="profile" *ngIf="!userTitle" class="welcome_name2" style="color:#1565c0;" >Logged in! Update Profile </span>

      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="drop down icon">
        <mat-icon style="color:#1565c0;">arrow_drop_down_circle</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="profile">
          <mat-icon>person</mat-icon>
          <span>My Profile</span>
        </button>
        <button  mat-menu-item routerLink="enrollment-list">
          <mat-icon>list</mat-icon>
          <span>My Course List</span>
        </button>
        <button mat-menu-item routerLink="semester-signup">
          <mat-icon>checklist</mat-icon>
          <span>Course Sign Ups</span>
        </button>
        <button mat-menu-item routerLink="course-proposal-wizard">
          <mat-icon>send</mat-icon>
          <span>Propose a Course</span>
        </button>
        <button mat-menu-item  (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>


        <span *ngIf="lightningService.role.view_admin_interface">
          <button mat-icon-button [matMenuTriggerFor]="adminmenu" aria-label="Example icon-button with a menu">
            <mat-icon style="color:#1565c0;">more_vert</mat-icon>
          </button>
          <mat-menu #adminmenu="matMenu">
            <button *ngIf="lightningService.role.view_admin_semesters" mat-menu-item routerLink="admin-semesters">
              <mat-icon>calendar_month</mat-icon>
              <span>Semesters</span>
            </button>
            <button *ngIf="lightningService.role.view_admin_concepts" mat-menu-item routerLink="admin-courses">
              <mat-icon>lightbulb</mat-icon>
              <span>Concepts</span>
            </button>
            <button *ngIf="lightningService.role.view_admin_users_interface" mat-menu-item routerLink="admin-users">
              <mat-icon>group</mat-icon>
              <span>Users</span>
            </button>
            <button *ngIf="lightningService.role.view_admin_roles_interface" mat-menu-item routerLink="admin-roles">
              <mat-icon>workspaces</mat-icon>
              <span>Roles</span>
            </button>
            <button *ngIf="lightningService.role.view_shepherd_resources" mat-menu-item routerLink="pages/shepherds">
              <mat-icon>groups</mat-icon>
              <span>Shepherds</span>
            </button>
          </mat-menu>
        </span>


      </span>

  <button mat-raised-button *ngIf="loginButtonVisible" (click)="loginChallenge()">Login</button>

</div>




<!-- Medium Toolbar -->
<div class="toolbar" role="banner" *ngIf="isMedium | async">
  <a mat-button routerLink="/"><b>HOME</b></a>
  <div class="spacer"></div>
  <button mat-button routerLink="/news">NEWS</button> 
  <div class="spacer"></div>
  <button mat-button routerLink="/course-list">COURSES</button>
  <div class="spacer"></div>
  <button mat-button routerLink="/pages/membership">MEMBERSHIP</button>
  <div class="spacer"></div>
  <button mat-button [matMenuTriggerFor]="activity_menu2">
    ACTIVITIES ▼
  </button>
  <mat-menu #activity_menu2="matMenu">
    <button mat-menu-item routerLink="/lunchlearn">
      <mat-icon>lunch_dining</mat-icon>
      <span>Lunch & Learn</span>
    </button>
    <button mat-menu-item routerLink="/programs">
      <mat-icon>stars</mat-icon>
      <span>Programs</span>
    </button>
    <button mat-menu-item routerLink="/enthusiastgroups">
      <mat-icon>casino</mat-icon>
      <span>Enthusiast Groups</span>
    </button>
  </mat-menu>
  <div class="spacer"></div>
  <button mat-button routerLink="/pages/contact">CONTACT</button>
  <div class="spacer"></div>
  <button mat-button [matMenuTriggerFor]="activity_menu">
    MORE ▼
   </button>
   <mat-menu #activity_menu="matMenu">
     <button mat-menu-item routerLink="/pages/about">ABOUT</button>
     <button mat-menu-item routerLink="/volunteer">OPPORTUNITIES</button>
   </mat-menu>
   <div class="spacer"></div>

  <span *ngIf="logoutButtonVisible">

    <span *ngIf="userTitle" class="welcome_name" style="color:#1565c0;" >{{ userTitle }} </span>
    <span routerLink="profile" *ngIf="!userTitle" class="welcome_name2" style="color:#1565c0;" >Logged in! Update Profile </span>

      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="drop down icon">
        <mat-icon style="color:#1565c0;">arrow_drop_down_circle</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="profile">
          <mat-icon>person</mat-icon>
          <span>My Profile</span>
        </button>
        <button  mat-menu-item routerLink="enrollment-list">
          <mat-icon>list</mat-icon>
          <span>My Course List</span>
        </button>
        <button  mat-menu-item routerLink="semester-signup">
          <mat-icon>checklist</mat-icon>
          <span>Course Sign Ups</span>
        </button>
        <button mat-menu-item routerLink="course-proposal-wizard">
          <mat-icon>send</mat-icon>
          <span>Propose a Course</span>
        </button>
        <button mat-menu-item  (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>


        <span *ngIf="lightningService.role.view_admin_interface">
          <button mat-icon-button [matMenuTriggerFor]="adminmenu" aria-label="Example icon-button with a menu">
            <mat-icon style="color:#1565c0;">more_vert</mat-icon>
          </button>
          <mat-menu #adminmenu="matMenu">
            <button *ngIf="lightningService.role.view_admin_semesters" mat-menu-item routerLink="admin-semesters">
              <mat-icon>calendar_month</mat-icon>
              <span>Semesters</span>
            </button>
            <button *ngIf="lightningService.role.view_admin_concepts" mat-menu-item routerLink="admin-courses">
              <mat-icon>lightbulb</mat-icon>
              <span>Concepts</span>
            </button>
            <button *ngIf="lightningService.role.view_admin_users_interface" mat-menu-item routerLink="admin-users">
              <mat-icon>group</mat-icon>
              <span>Users</span>
            </button>
            <button *ngIf="lightningService.role.view_admin_roles_interface" mat-menu-item routerLink="admin-roles">
              <mat-icon>workspaces</mat-icon>
              <span>Roles</span>
            </button>
            <button *ngIf="lightningService.role.view_shepherd_resources" mat-menu-item routerLink="pages/shepherds">
              <mat-icon>groups</mat-icon>
              <span>Shepherds</span>
            </button>
          </mat-menu>
        </span>


      </span>

  <button mat-raised-button *ngIf="loginButtonVisible" (click)="loginChallenge()">Login</button>

</div>




<!-- LARGE and XLARGE Toolbar -->
<div class="toolbar" role="banner" *ngIf="(isLarge | async) || (isXLarge | async)">

  <a mat-button routerLink="/">HOME</a>
  <div class="spacer"></div>
  <button mat-button routerLink="/news">NEWS</button>
  <div class="spacer"></div>
  <button mat-button routerLink="/pages/about">ABOUT</button>
  <div class="spacer"></div>
  <button mat-button routerLink="/course-list">COURSES</button>
  <div class="spacer"></div>
  <button mat-button [matMenuTriggerFor]="activity_menu">
    ACTIVITIES ▼
  </button>
  <mat-menu #activity_menu="matMenu">
    <button mat-menu-item routerLink="/lunchlearn">
      <mat-icon>lunch_dining</mat-icon>
      <span>Lunch & Learn</span>
    </button>
    <button mat-menu-item routerLink="/programs">
      <mat-icon>stars</mat-icon>
      <span>Programs</span>
    </button>
    <button mat-menu-item routerLink="/enthusiastgroups">
      <mat-icon>casino</mat-icon>
      <span>Enthusiast Groups</span>
    </button>
  </mat-menu>
  <div class="spacer"></div>
  <button mat-button routerLink="/pages/membership">MEMBERSHIP</button>
  <div class="spacer"></div>
  <button mat-button routerLink="/volunteer">OPPORTUNITIES</button>
  <div class="spacer"></div>
  <button mat-button routerLink="/pages/contact">CONTACT</button>

      <div class="spacer"></div>

      <span *ngIf="logoutButtonVisible">

      <span *ngIf="userTitle" class="welcome_name" style="color:#1565c0;" >{{ userTitle }} </span>
      <span routerLink="profile" *ngIf="!userTitle" class="welcome_name2" style="color:#1565c0;" >Logged in! Update Profile </span>

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="drop down icon">
          <mat-icon style="color:#1565c0;">arrow_drop_down_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item routerLink="profile">
            <mat-icon>person</mat-icon>
            <span>My Profile</span>
          </button>
          <button  mat-menu-item routerLink="enrollment-list">
            <mat-icon>list</mat-icon>
            <span>My Course List</span>
          </button>
          <button  mat-menu-item routerLink="semester-signup">
            <mat-icon>checklist</mat-icon>
            <span>Course Sign Ups</span>
          </button>
          <button mat-menu-item routerLink="course-proposal-wizard">
            <mat-icon>send</mat-icon>
            <span>Propose a Course</span>
          </button>
          <button mat-menu-item  (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>


          <span *ngIf="lightningService.role.view_admin_interface">
            <button mat-icon-button [matMenuTriggerFor]="adminmenu" aria-label="Example icon-button with a menu">
              <mat-icon style="color:#1565c0;">more_vert</mat-icon>
            </button>
            <mat-menu #adminmenu="matMenu">
              <button *ngIf="lightningService.role.view_admin_semesters" mat-menu-item routerLink="admin-semesters">
                <mat-icon>calendar_month</mat-icon>
                <span>Semesters</span>
              </button>
              <button *ngIf="lightningService.role.view_admin_concepts" mat-menu-item routerLink="admin-courses">
                <mat-icon>lightbulb</mat-icon>
                <span>Concepts</span>
              </button>
              <button *ngIf="lightningService.role.view_admin_users_interface" mat-menu-item routerLink="admin-users">
                <mat-icon>group</mat-icon>
                <span>Users</span>
              </button>
              <button *ngIf="lightningService.role.view_admin_roles_interface" mat-menu-item routerLink="admin-roles">
                <mat-icon>workspaces</mat-icon>
                <span>Roles</span>
              </button>
              <button *ngIf="lightningService.role.view_shepherd_resources" mat-menu-item routerLink="pages/shepherds">
                <mat-icon>groups</mat-icon>
                <span>Shepherds</span>
              </button>
            </mat-menu>
          </span>


        </span>

    <button mat-raised-button *ngIf="loginButtonVisible" (click)="loginChallenge()">Login</button>
</div>



<div class="width" routerLink="profile" *ngIf="!lightningService.user['travel_request_active'] && lightningService.user['member'] || !lightningService.user['liability_waiver_active'] && lightningService.user['member']">
  <div class="redbarheader" >REQUIRED ACTION: Update Profile and Travel & Liability Forms</div>
</div>



<div class="content-margin-will" role="main"> 

<router-outlet></router-outlet>

</div>



<div class="footer">
  <a class="black-anchor" href="/pages/agreement">User Agreement</a> &nbsp; | &nbsp; <a class="black-anchor" href="/pages/privacy">Privacy Policy</a> &nbsp; | &nbsp;
  <img src="/assets/images/wnmu_logo.png" alt="wnmu logo" style="padding: 15px 0 15px 10px;" width="200" />
</div>