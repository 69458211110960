


<div class="toolbarheader" >
  {{ title.value }} SEMESTER
</div>

<p style="text-align:right;padding-top:10px;"><b>{{ semester_coursemember_count }}</b> seats requested 
<br/><b>{{ distinct_user_count }}</b> members signed up
</p>

<mat-divider></mat-divider>
<br/>

<div *ngIf="lightningService.role.view_admin_semesters">
<b>{{courseDuration.value.starts | date:'MMM dd, yyyy'}} - {{courseDuration.value.ends | date:'MMM dd, yyyy'}}</b>
<br/>
{{description.value}}

</div>

<div *ngIf="lightningService.role.update_semesters">
  <br/>
  <mat-accordion>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          EDIT SEMESTER
        </mat-panel-title>
        <mat-panel-description>
  &nbsp;
          <mat-icon class="icon-size">edit</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

<form #semesterUpdateForm="ngForm" >
    <mat-form-field class="example-form-field">
        <mat-label for="title">Title (Year & Season) </mat-label>
        <input matInput id="title" type="text" [formControl]="title">
    </mat-form-field>
    <br/>
    <mat-form-field class="example-form-field">
        <mat-label>Semester Duration</mat-label>
        <mat-date-range-input
          [formGroup]="courseDuration"
          [rangePicker]="courseDurationPicker"
          [comparisonStart]="courseDuration.value.starts"
          [comparisonEnd]="courseDuration.value.ends">
          <input matStartDate placeholder="Start date" formControlName="starts">
          <input matEndDate placeholder="End date" formControlName="ends">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="courseDurationPicker"></mat-datepicker-toggle>
        <mat-date-range-picker #courseDurationPicker></mat-date-range-picker>
      </mat-form-field>
      <br/>
      <br/>
      <mat-form-field class="example-full-width">
        <mat-label for="description">Admin Notes </mat-label>
        <textarea matInput id="description" type="text" [formControl]="description"></textarea>
    </mat-form-field>

<mat-divider></mat-divider>
<br/>
<H2>ACTIVATE</H2>

  <p><mat-checkbox [formControl]="active">Semester Active</mat-checkbox><br/>
  <mat-hint>Only one semester can be active at a time</mat-hint></p>

<br/>
  <p><mat-checkbox [formControl]="signups_active">Sign Ups Active</mat-checkbox><br/>
  <mat-hint>Only active semesters can have active signups</mat-hint></p>
  <br/>

  <mat-checkbox [formControl]="max_course_signups_boolean">Restrict # of Course Signups Per User</mat-checkbox>
  <br/>
      <mat-form-field *ngIf="max_course_signups_boolean.value">
          <mat-label for="max_course_signups">Set Max #: </mat-label>
          <input matInput id="max_course_signups" type="text" [formControl]="max_course_signups">
      </mat-form-field>
      <br/>
      <br/>
</form>
<button mat-raised-button color="primary" type="button" (click)="updateSemester()">Update</button>

</mat-expansion-panel>
</mat-accordion>
</div>

<br/>
<div class="graybar">{{ title.value }} COURSES 
</div>
<p style="text-align:right;padding-top:10px;"><b>{{ course_list.length }}</b> total courses 
</p>

<mat-divider></mat-divider>
<br/>

<div *ngFor="let site_course of course_list">
<table routerLink="/admin-course-detail/{{ site_course['_id'] }}">
  <tr>
    <th rowspan="3">
  <mat-icon matTooltip="(1) Idea with no facilitator" class="status-icon" *ngIf="site_course['status_number'] == 1"  >lightbulb_outline</mat-icon>
  <mat-icon matTooltip="(2) Facilitator suggested" class="status-icon" *ngIf="site_course['status_number'] == 2"  >thumb_up</mat-icon>
  <mat-icon matTooltip="(3) Proposal submitted" class="status-icon" *ngIf="site_course['status_number'] == 3"  >assignment</mat-icon>
  <mat-icon matTooltip="(4) Facilitator response requested" class="status-icon" *ngIf="site_course['status_number'] == 4"  >date_range</mat-icon>
  <mat-icon matTooltip="(5) Transfer to semester page" class="status-icon" *ngIf="site_course['status_number'] == 5"  >done</mat-icon>
  <mat-icon matTooltip="(6) Final confirmation received" class="status-icon" *ngIf="site_course['status_number'] == 6"  >done_all</mat-icon>
  <mat-icon matTooltip="(7) Course cancelled" class="status-icon" *ngIf="site_course['status_number'] == 7"  >cancel</mat-icon>
    </th>
    <th rowspan="3" *ngIf="site_course['photo']"><img class="circle-image" src="{{site_course['photo']}}" alt="picture"/>
    </th>
    <th rowspan="3" *ngIf="!site_course['photo']"><img class="circle-image" src="/assets/images/graysquare.jpg" alt="graysquare">
    </th>
    <td matListItemTitle> <b>#{{site_course['order']}}: {{site_course['title']}}</b>
    </td>
  </tr>
  <tr>
    <i><b>F:</b> {{site_course['facilitator_name']}}, <b>S:</b> {{site_course['shepherd_name']}}, <b>A:</b> {{site_course['assignee']}}<ng-container *ngIf="site_course['first_aid_user_id']"> // <span style="color:purple;"> <b>FAP</b></span></ng-container><ng-container *ngIf="site_course['av_checklist'] && site_course['av_required']"> // <span style="color:blue;"> <b>A/V:</b> {{site_course['av_checklist']}}</span></ng-container></i>
  </tr>
  <tr>
    <td>{{site_course['notes']}} &#64; {{site_course['location']}}
    </td>
  </tr>
</table>
</div>
