import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';

import { LightningService } from '../lightning.service';
import { GenericErrorDialogComponent } from '../generic-error-dialog/generic-error-dialog.component';
import { DeleteConfirmDialogComponent } from '../delete-confirm-dialog/delete-confirm-dialog.component'

@Component({
  selector: 'app-admin-role-detail',
  templateUrl: './admin-role-detail.component.html',
  styleUrls: ['./admin-role-detail.component.css']
})
export class AdminRoleDetailComponent {
  constructor(private lightningService: LightningService, private route: ActivatedRoute, public dialog: MatDialog, private router: Router) { }


  roleid ="";
  public role = {};

  public user_list = [];
  public user_count = 0;

  // load the role into the form 
  ngOnInit(): void {
    console.log('role detail init');
    
    this.route.paramMap.subscribe((params) => {
      console.log('role data params changed')
      this.roleid = params.get('roleid') || "";
      //this.role = this.productService.getProduct(this.id);
      this.getRoleData();
      this.getUsersData();

    });

    

  }

  getRoleData = async () => {
    if (localStorage.getItem("token") === null) {
      console.log('role data - no access token');
    }
    else
    {
      const response = await fetch(this.lightningService.apiUrl + '/admin/roles/' + this.roleid, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      console.log(result.role); 
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
      if (result.role) {
        console.log('found role');
        console.log(result.role['title'])
        console.log(result.role['description'])
        this.role = result.role;
        this.title.setValue(result.role['title']);
        this.description.setValue(result.role['description']);

        this.create_concepts.setValue(result.role['create_concepts']);
        this.create_eg.setValue(result.role['create_eg']);
        this.create_lunchlearn.setValue(result.role['create_lunchlearn']);
        this.create_news.setValue(result.role['create_news']);
        this.create_programs.setValue(result.role['create_programs']);
        this.create_roles.setValue(result.role['create_roles']);
        this.create_semesters.setValue(result.role['create_semesters']);
        this.create_travelgroup.setValue(result.role['create_travelgroup']);
        this.create_users.setValue(result.role['create_users']);
        this.create_volunteer.setValue(result.role['create_volunteer']);
        this.create_card.setValue(result.role['create_card']);
        this.delete_concepts.setValue(result.role['delete_concepts']);
        this.delete_course_members.setValue(result.role['delete_course_members']);
        this.delete_eg.setValue(result.role['delete_eg']);
        this.delete_lunchlearn.setValue(result.role['delete_lunchlearn']);
        this.delete_news.setValue(result.role['delete_news']);
        this.delete_programs.setValue(result.role['delete_programs']);
        this.delete_roles.setValue(result.role['delete_roles']);
        this.delete_travelgroup.setValue(result.role['delete_travelgroup']);
        this.delete_users.setValue(result.role['delete_users']);
        this.delete_volunteer.setValue(result.role['delete_volunteer']);
        this.delete_card.setValue(result.role['delete_card']);
        this.update_concepts.setValue(result.role['update_concepts']);
        this.update_course_members.setValue(result.role['update_course_members']);
        this.update_eg.setValue(result.role['update_eg']);
        this.update_lunchlearn.setValue(result.role['update_lunchlearn']);
        this.update_news.setValue(result.role['update_news']);
        this.update_programs.setValue(result.role['update_programs']);
        this.update_roles.setValue(result.role['update_roles']);
        this.update_semesters.setValue(result.role['update_semesters']);
        this.update_travelgroup.setValue(result.role['update_travelgroup']);
        this.update_users.setValue(result.role['update_users']);
        this.update_volunteer.setValue(result.role['update_volunteer']);
        this.update_pages.setValue(result.role['update_pages']);
        this.update_card.setValue(result.role['update_card']);
        this.view_admin_concepts.setValue(result.role['view_admin_concepts']);
        this.view_admin_courses.setValue(result.role['view_admin_courses']);
        this.view_admin_interface.setValue(result.role['view_admin_interface']);
        this.view_admin_roles_interface.setValue(result.role['view_admin_roles_interface']);
        this.view_admin_semesters.setValue(result.role['view_admin_semesters']);
        this.view_admin_users_interface.setValue(result.role['view_admin_users_interface']);
        this.view_course_members.setValue(result.role['view_course_members']);
        this.view_shepherd_resources.setValue(result.role['view_shepherd_resources']);

      }
    }
  }

  
  
  title = new FormControl('');
  description = new FormControl('');
  create_concepts = new FormControl('');
  create_eg = new FormControl('');
  create_lunchlearn = new FormControl('');
  create_news = new FormControl('');
  create_programs = new FormControl('');
  create_roles = new FormControl('');
  create_semesters = new FormControl('');
  create_travelgroup = new FormControl('');
  create_users = new FormControl('');
  create_volunteer = new FormControl('');
  create_card = new FormControl('');
  delete_concepts = new FormControl('');
  delete_course_members = new FormControl('');
  delete_eg = new FormControl('');
  delete_lunchlearn = new FormControl('');
  delete_news = new FormControl('');
  delete_programs = new FormControl('');
  delete_roles = new FormControl('');
  delete_travelgroup = new FormControl('');
  delete_users = new FormControl('');
  delete_volunteer = new FormControl('');
  delete_card = new FormControl('');
  update_concepts = new FormControl('');
  update_course_members = new FormControl('');
  update_eg = new FormControl('');
  update_lunchlearn = new FormControl('');
  update_news = new FormControl('');
  update_programs = new FormControl('');
  update_roles = new FormControl('');
  update_semesters = new FormControl('');
  update_travelgroup = new FormControl('');
  update_users = new FormControl('');
  update_volunteer = new FormControl('');
  update_pages = new FormControl('');
  update_card = new FormControl('');
  view_admin_concepts = new FormControl('');
  view_admin_courses = new FormControl('');
  view_admin_interface = new FormControl('');
  view_admin_roles_interface = new FormControl('');
  view_admin_semesters = new FormControl('');
  view_admin_users_interface = new FormControl('');
  view_course_members = new FormControl('');
  view_shepherd_resources = new FormControl('');

  updateRole = async () => {
    console.log('update role');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'_id':this.roleid,
         'title':this.title.value,
        'description': this.description.value,
        'create_concepts': this.create_concepts.value,
        'create_eg': this.create_eg.value,
        'create_lunchlearn': this.create_lunchlearn.value,
        'create_news': this.create_news.value,
        'create_programs': this.create_programs.value,
        'create_roles': this.create_roles.value,
        'create_semesters': this.create_semesters.value,
        'create_travelgroup': this.create_travelgroup.value,
        'create_users': this.create_users.value,
        'create_volunteer': this.create_volunteer.value,
        'create_card': this.create_card.value,
        'delete_concepts': this.delete_concepts.value,
        'delete_course_members': this.delete_course_members.value,
        'delete_eg': this.delete_eg.value,
        'delete_lunchlearn': this.delete_lunchlearn.value,
        'delete_news': this.delete_news.value,
        'delete_programs': this.delete_programs.value,
        'delete_roles': this.delete_roles.value,
        'delete_travelgroup': this.delete_travelgroup.value,
        'delete_users': this.delete_users.value,
        'delete_volunteer': this.delete_volunteer.value,
        'delete_card': this.delete_card.value,
        'update_concepts': this.update_concepts.value,
        'update_course_members': this.update_course_members.value,
        'update_eg': this.update_eg.value,
        'update_lunchlearn': this.update_lunchlearn.value,
        'update_news': this.update_news.value,
        'update_programs': this.update_programs.value,
        'update_roles': this.update_roles.value,
        'update_semesters': this.update_semesters.value,
        'update_travelgroup': this.update_travelgroup.value,
        'update_users': this.update_users.value,
        'update_volunteer': this.update_volunteer.value,
        'update_pages': this.update_pages.value,
        'update_card': this.update_card.value,
        'view_admin_concepts': this.view_admin_concepts.value,
        'view_admin_courses': this.view_admin_courses.value,
        'view_admin_interface': this.view_admin_interface.value,
        'view_admin_roles_interface': this.view_admin_roles_interface.value,
        'view_admin_semesters': this.view_admin_semesters.value,
        'view_admin_users_interface': this.view_admin_users_interface.value,
        'view_course_members': this.view_course_members.value,
        'view_shepherd_resources': this.view_shepherd_resources.value
      
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/roles/' + this.roleid + '/update', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        console.log(data);
        

        // If error, display dialog
        if (data['success'] == false)
        {
          this.dialog.open(GenericErrorDialogComponent, {
            data: {
              error_message: data['error_message'],
            },
          });
        } 
        else 
        {
          this.router.navigate(['admin-roles']);
        }

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

  openDeleteConfirmDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {title: "Confirm Deletion", description: "Are you sure you want to delete this role? This action cannot be undone. Please remove all users from this role before deleting the role."},
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('The dialog was closed');
      //this.animal = result;
      if (data) {
        console.log('Confirm was pressed');
        this.deleteRole();
      }
    });
  }

  deleteRole = async () => {
    console.log('delete role');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'_id':this.roleid})
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/roles/' + this.roleid + '/delete', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        console.log(data);

        // If error, display dialog
        if (data['success'] == false)
        {
          this.dialog.open(GenericErrorDialogComponent, {
            data: {
              error_message: data['error_message'],
            },
          });
        }
        else 
        {
          this.router.navigate(['admin-roles']);
        }

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

  getUsersData = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/admin/users/with_role/' + this.roleid, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
  
      this.user_list = result.users;
      this.user_count = result.user_count;
  
    }


}
