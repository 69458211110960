import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { FormControl } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';

import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';

import { AdminComponent } from './admin/admin.component';
import { ProfileComponent } from './profile/profile.component';
import { AdminRolesComponent } from './admin-roles/admin-roles.component';
import { AdminRoleCreateComponent } from './admin-role-create/admin-role-create.component';
import { AdminRoleDetailComponent } from './admin-role-detail/admin-role-detail.component';
import { AdminUserDetailComponent } from './admin-user-detail/admin-user-detail.component';
import { AdminCoursesComponent } from './admin-courses/admin-courses.component';
import { AdminCourseCreateComponent } from './admin-course-create/admin-course-create.component';
import { AdminCourseDetailComponent } from './admin-course-detail/admin-course-detail.component';
import { AdminSemestersComponent } from './admin-semesters/admin-semesters.component';
import { AdminSemesterCreateComponent } from './admin-semester-create/admin-semester-create.component';
import { AdminSemesterDetailComponent } from './admin-semester-detail/admin-semester-detail.component';
import { AdminPageCreateComponent } from './admin-page-create/admin-page-create.component';
import { AdminPageDetailComponent } from './admin-page-detail/admin-page-detail.component';
import { SafePipe } from './safe.pipe';
import { HomepageComponent } from './homepage/homepage.component';
import { AdminPageUpdateComponent } from './admin-page-update/admin-page-update.component';
import { EnrollmentListComponent } from './enrollment-list/enrollment-list.component';
import { SemesterSignupComponent } from './semester-signup/semester-signup.component';
import { GenericErrorDialogComponent } from './generic-error-dialog/generic-error-dialog.component';
import { CourseListComponent } from './course-list/course-list.component';
import { GenericResponseDialogComponent } from './generic-response-dialog/generic-response-dialog.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { SearchPipe } from './search.pipe';
import { AdminCardCreateComponent } from './admin-card-create/admin-card-create.component';
import { AdminCardDetailComponent } from './admin-card-detail/admin-card-detail.component';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NewsPageComponent } from './news-page/news-page.component';
import { LunchlearnPageComponent } from './lunchlearn-page/lunchlearn-page.component';
import { ProgramsPageComponent } from './programs-page/programs-page.component';
import { EnthusiastgroupsPageComponent } from './enthusiastgroups-page/enthusiastgroups-page.component';
import { VolunteerPageComponent } from './volunteer-page/volunteer-page.component';
import { CourseProposalWizardComponent } from './course-proposal-wizard/course-proposal-wizard.component';
import { DeleteConfirmDialogComponent } from './delete-confirm-dialog/delete-confirm-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminUsersComponent,
    AdminComponent,
    ProfileComponent,
    AdminRolesComponent,
    AdminRoleCreateComponent,
    AdminRoleDetailComponent,
    AdminUserDetailComponent,
    AdminCoursesComponent,
    AdminCourseCreateComponent,
    AdminCourseDetailComponent,
    AdminSemestersComponent,
    AdminSemesterCreateComponent,
    AdminSemesterDetailComponent,
    AdminPageCreateComponent,
    AdminPageDetailComponent,
    SafePipe,
    HomepageComponent,
    AdminPageUpdateComponent,
    EnrollmentListComponent,
    SemesterSignupComponent,
    GenericErrorDialogComponent,
    CourseListComponent,
    GenericResponseDialogComponent,
    CourseDetailComponent,
    SearchPipe,
    AdminCardCreateComponent,
    AdminCardDetailComponent,
    HeaderComponent,
    NewsPageComponent,
    LunchlearnPageComponent,
    ProgramsPageComponent,
    EnthusiastgroupsPageComponent,
    VolunteerPageComponent,
    CourseProposalWizardComponent,
    DeleteConfirmDialogComponent,
    //LoginDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule, MatSelectModule, MatInputModule, MatGridListModule, MatCardModule, MatExpansionModule, MatToolbarModule, MatSidenavModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}
