import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-admin-courses',
  templateUrl: './admin-courses.component.html',
  styleUrls: ['./admin-courses.component.css']
})
export class AdminCoursesComponent {
  constructor(public lightningService: LightningService) { }

  // keep track of the state of the UI
  // 0 : active semester
  // 1 : active semester AV
  // 2 : concepts
  // 3 : archives
  public ui_mode = 0; // default to active semester 

  public archived_course_list = [];
  public concept_course_list = [];
  public active_semester_course_list = [];
  public av_course_list = [];
  public semester_list = [];
  public semesterid_selected = "";

  semesterSelectFormControl = new FormControl('');

  ngOnInit(): void {
    console.log('admin courses init');
    //this.title.setValue(this.lightningService.user['title']);
    this.getCourses();
    this.getSemesterData();

    this.semesterSelectFormControl.valueChanges.subscribe(value => {
      this.getArchivedCourses();
    });

  }

  getCourses = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/activesemester/courses/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
 
      this.active_semester_course_list = result;

      // copy only the AV items to the av list
      for (let i = 0; i < this.active_semester_course_list.length; i++) {
        if (this.active_semester_course_list[i]['av_required'] )
        {
          this.av_course_list.push(this.active_semester_course_list[i]);
        }
      }


      // also get the concepts
      const concept_response = await fetch(this.lightningService.apiUrl + '/conceptcourses/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const concept_result = await concept_response.json();
      console.log(concept_result);
      if (concept_result.status == "401")
      {
        this.lightningService.requestLogin();
      }
 
      this.concept_course_list = concept_result;
  
    }

  getSemesterData = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/semesters/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
  
      this.semester_list = result;
  
    }

  onUIChange(ui_mode: any){
    if (ui_mode == 0)
    {
      this.ui_mode = 0;
    }
    else if (ui_mode == 1)
    {
      this.ui_mode = 1;
    }
    else if (ui_mode == 2)
    {
      this.ui_mode = 2;
    }
    else if (ui_mode == 3)
    {
      this.ui_mode = 3;
    }


  }

  getArchivedCourses = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/semesters/' + this.semesterSelectFormControl.value + '/courses/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
 
      this.archived_course_list = result;

  
    }

  


}
