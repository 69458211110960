
<div class="toolbarheader" >
    EDIT A CARD
  </div>

  <br/>
  <img *ngIf="header_image.value" src="{{header_image.value}}" width="200px;" style="padding-bottom:15px;">

<form #cardUpdateForm="ngForm">
    <mat-form-field class="example-full-width">
        <mat-label for="title">Card Title </mat-label>
        <input matInput id="title" type="text" [formControl]="title">
    </mat-form-field>
    <br/>
    <mat-form-field class="example-full-width">
        <mat-label for="header_image">Image </mat-label>
        <input matInput id="header_image" type="text" [formControl]="header_image">
        <mat-hint>https://lh3.googleusercontent.com/d/<b>[file ID]</b> --- <i>200px width</i></mat-hint>
    </mat-form-field>
    <br/><br/>
    <mat-form-field class="example-full-width">
        <mat-label for="html_safe_text">Info (HTML-safe) </mat-label>
        <textarea rows="12" matInput id="html_safe_text" type="text" [formControl]="html_safe_text"></textarea>
    </mat-form-field>
    <br/>
    <mat-form-field>
        <mat-label for="order">Order of Appearance </mat-label>
        <input matInput id="order" type="number" [formControl]="order">
        <mat-hint>Enter a number only.</mat-hint>
      </mat-form-field>
      <br/><br/>
</form>

<button *ngIf="lightningService.role.create_card" mat-raised-button color="primary" type="button" (click)="updateCard()">Update</button>&nbsp;
<button *ngIf="lightningService.role.create_card" mat-raised-button color="warn" type="button" (click)="openDeleteConfirmDialog()">Delete</button>