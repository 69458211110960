

<div class="toolbarheader" >
    UPDATE HTML: {{ title.value }}
</div>
<br/>

<form #pageUpdateForm="ngForm">
    <mat-form-field class="example-full-width">
        <mat-label for="title">Banner Title</mat-label>
        <input matInput id="title" type="text" [formControl]="title">
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label for="header_image">Banner Image </mat-label>
        <input matInput id="header_image" type="text" [formControl]="header_image">
        <mat-hint><b>1024 x 220px</b> --- https://drive.google.com/uc?id=</mat-hint>
    </mat-form-field>
<br/><br/>
    <mat-form-field class="example-full-width">
        <mat-label for="html_safe_text">HTML </mat-label>
        <textarea rows="16" matInput id="html_safe_text" type="text" [formControl]="html_safe_text"></textarea>
    </mat-form-field>

</form>

<button mat-raised-button color="primary" type="button" (click)="updatePage()">Update</button>