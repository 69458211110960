<div class="toolbarheader">
  <span *ngIf="concept.value">CONCEPT&nbsp;</span> <span *ngIf="!concept.value">COURSE&nbsp;</span> DETAILS</div>

<p style="text-align:right;padding-top:10px;">Submitted By: <b><a href="/admin-user-detail/{{submittedby_user_id}}">{{ submittedby_user_title }}</a> </b>
  <br/>
  Created: <b>{{created | date: 'mediumDate'}}</b>
  <br/>
  Last Updated: <b>{{updated | date: 'mediumDate'}}</b>
</p>
  
  <mat-divider></mat-divider>
  <br/>



  <img *ngIf="!concept.value" src="{{ photo.value }}" alt="course photo" class="responsive" />


<h2><span *ngIf="order.value">#{{ order.value }}</span> {{ title.value }}</h2> 

<div *ngIf="lightningService.role.update_concepts">
  
<mat-accordion>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        EDIT FIELDS
      </mat-panel-title>
      <mat-panel-description>
&nbsp;
        <mat-icon class="icon-size">edit</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
<form #roleUpdateForm="ngForm">

  <button mat-raised-button color="primary" type="button" (click)="updateCourse()">Update</button>
  <br/><br/>
  <mat-form-field>
    <mat-label for="order">Course Number </mat-label>
    <input matInput id="order" type="number" [formControl]="order">
    <mat-hint>Enter a number only.</mat-hint>
  </mat-form-field>
  <br/><br/>

  <mat-form-field class="example-full-width">
    <mat-label for="title">Title </mat-label>
    <input matInput id="title" type="text" [formControl]="title">
</mat-form-field>

  <mat-form-field class="half-width">
    <mat-label for="status_number">Status Number </mat-label>
    <mat-select matInput id="status_number" [formControl]="status_number" type="number" matNativeControl>
      <mat-option value="1"><mat-icon>lightbulb_outline</mat-icon><b>(1)</b> Idea with no facilitator</mat-option>
      <mat-option value="2"><mat-icon>thumb_up</mat-icon><b>(2)</b> Facilitator suggested</mat-option>
      <mat-option value="3"><mat-icon>assignment</mat-icon><b>(3)</b> Proposal submitted</mat-option>
      <mat-option value="4"><mat-icon>date_range</mat-icon><b>(4)</b> Facilitator response requested</mat-option>
      <mat-option value="5"><mat-icon>done</mat-icon><b>(5)</b> Transfer to semester page</mat-option>
      <mat-option value="6"><mat-icon>done_all</mat-icon><b>(6)</b> Final confirmation received</mat-option>
      <mat-option value="7"><mat-icon>cancel</mat-icon><b>(7)</b> Course cancelled</mat-option>
    </mat-select>
  </mat-form-field>
<br/>
  <mat-form-field class="third-width">
    <mat-label>Assignee Name</mat-label>
    <input matInput id="assignee" type="text" [formControl]="assignee">
  </mat-form-field>
<br/>
<mat-form-field class="half-width">
  <mat-label>Facilitator ID</mat-label>
  <input matInput id="facilitator_id" type="text" [formControl]="facilitator_id">
  <mat-hint><a href="/admin-user-detail/{{facilitator_id.value}}">{{facilitator_name.value}}: View Profile</a></mat-hint>
</mat-form-field>
<br/><br/>

<mat-form-field>
  <mat-label>Facilitator Name</mat-label>
  <input matInput id="facilitator_name" type="text" [formControl]="facilitator_name">
  <mat-hint>Empty to auto-populate.</mat-hint>
</mat-form-field>

<mat-form-field>
  <mat-label>Facilitator Email</mat-label>
  <input matInput id="facilitator_email" type="text" [formControl]="facilitator_email">
  <mat-hint>Empty to auto-populate.</mat-hint>
</mat-form-field>

<br/><br/>

<mat-form-field class="example-full-width">
  <mat-label for="description">Course Description </mat-label>
  <textarea rows="3" matInput id="description" type="text" [formControl]="description"></textarea>
</mat-form-field>

<mat-form-field class="example-full-width">
  <mat-label for="facilitator_bio">Facilitator Bio </mat-label>
  <textarea rows="3" matInput id="facilitator_bio" type="text" [formControl]="facilitator_bio"></textarea>
</mat-form-field>


<mat-form-field class="half-width">
  <mat-label>Shepherd ID</mat-label>
  <input matInput id="shepherd_id" type="text" [formControl]="shepherd_id">
  <mat-hint><a href="/admin-user-detail/{{shepherd_id.value}}">{{shepherd_name.value}}: View Profile</a></mat-hint>
</mat-form-field>
<br/>
<br/>

<mat-form-field class="half-width">
  <mat-label>First Aid Provider ID</mat-label>
  <input matInput id="first_aid_user_id" type="text" [formControl]="first_aid_user_id">
  <mat-hint><a href="/admin-user-detail/{{first_aid_user_id.value}}">View Profile</a></mat-hint>
</mat-form-field>
<br/>
<br/>

<mat-form-field class="example-full-width">
  <mat-label for="subject_category">Subject Category </mat-label>
  <mat-select matInput id="subject_category" type="text" [formControl]="subject_category" matNativeControl>
    <mat-option value="Art, Music & Dance">[01] Art, Music & Dance</mat-option>
    <mat-option value="Literature, Film & Theater">[02] Literature, Film & Theater</mat-option>
    <mat-option value="History & Current Affairs">[03] History & Current Affairs</mat-option>
    <mat-option value="Science & Nature">[04] Science & Nature</mat-option>
    <mat-option value="Technology">[05] Technology</mat-option>
    <mat-option value="Health & Fitness">[06] Health & Fitness</mat-option>
    <mat-option value="Outdoor Experience">[07] Outdoor Experience</mat-option>
    <mat-option value="Out-of-Town Excursions">[08] Out-of-Town Excursions</mat-option>
    <mat-option value="Armchair Travel">[09] Armchair Travel</mat-option>
    <mat-option value="Self Discovery">[10] Self Discovery</mat-option>
    <mat-option value="Cooking & Food">[11] Cooking & Food</mat-option>
    <mat-option value="Games">[12] Games</mat-option>
    <mat-option value="How To/Everything Else">[13] How To/Everything Else</mat-option>
    <mat-option value="Local Issues & Adventures">[14] Local Issues & Adventures</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field class="example-full-width">
  <mat-label for="semester_request">Semester Requested </mat-label>
  <input matInput id="semester_request" type="text" [formControl]="semester_request">
  <mat-hint>This field does NOT show on the public course page.</mat-hint>
</mat-form-field>

<br/><br/>

<mat-form-field class="example-full-width">
  <mat-label for="preferred_schedule_request_text">Preferred Schedule Requested </mat-label>
  <textarea rows="3" matInput id="preferred_schedule_request_text" type="text" [formControl]="preferred_schedule_request_text"></textarea>
  <mat-hint>This field does NOT show on the public course page.</mat-hint>
</mat-form-field>
  <br/><br/>

  <mat-form-field class="example-full-width">
    <mat-label for="notes">Sessions/Date/Time </mat-label>
    <textarea matInput id="notes" type="text" [formControl]="notes"></textarea>
  </mat-form-field>



  <mat-form-field>
    <mat-label>First Day of Class</mat-label>
    <input matInput type="text" id="start_date" [formControl]="start_date" [matDatepicker]="picker">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <br/><br/>
  
  <mat-form-field class="example-full-width">
    <mat-label for="facilitator_shepherd_requested">Admin Notes</mat-label>
    <textarea matInput id="facilitator_shepherd_requested" type="text" [formControl]="facilitator_shepherd_requested"></textarea>
    <mat-hint>This field does NOT show on the public course page.
    </mat-hint>
  </mat-form-field>
<br/><br/>

  <mat-form-field>
    <mat-label for="capacity">Max Capacity </mat-label>
    <input matInput id="capacity" type="number" [formControl]="capacity">
    <mat-hint>Enter max number only.</mat-hint>
  </mat-form-field>


<mat-form-field>
  <mat-label for="minimum_capacity">Minimum Capacity </mat-label>
  <input matInput id="minimum_capacity" type="number" [formControl]="minimum_capacity">
  <mat-hint>Enter minimum number only.</mat-hint>
</mat-form-field>

<br/><br/>
<mat-form-field>
  <mat-label for="fees">Fees </mat-label>
  <input matInput id="fees" type="number" [formControl]="fees">
  <mat-hint>Enter a number only. If there is no fee, <b>leave blank</b>.</mat-hint>
</mat-form-field>
<br/><br/>
<mat-form-field class="example-full-width">
  <mat-label for="fees_description">Fees Description </mat-label>
  <textarea matInput id="fees_description" type="text" [formControl]="fees_description"></textarea>
</mat-form-field>


<mat-form-field class="example-full-width">
  <mat-label for="location">Location </mat-label>
  <input matInput id="location" type="text" [formControl]="location">
</mat-form-field>


<mat-form-field class="example-full-width">
  <mat-label for="location_type">Location Type </mat-label>
  <mat-select matInput id="location_type" type="text" [formControl]="location_type" matNativeControl>
    <mat-option value="In a classroom">In a classroom</mat-option>
    <mat-option value="In an inside space that is not a classroom">In an inside space that is not a classroom</mat-option>
    <mat-option value="In an outside space">In an outside space</mat-option>
    <mat-option value="Both in a classroom and in an outside space">Both in a classroom and in an outside space</mat-option>
    <mat-option value="An excursion, hike, or field trip in town">An excursion, hike, or field trip in town</mat-option>
    <mat-option value="An excursion, hike, or field trip out of town">An excursion, hike, or field trip out of town</mat-option>
    <mat-option value="An inside game course">An inside game course</mat-option>
    <mat-option value="An outside game course">An outside game course</mat-option>
    <mat-option value="On Zoom">On Zoom</mat-option>
  </mat-select>
  <mat-hint>This field does NOT show on the public course page.</mat-hint>
</mat-form-field>
<br/><br/>

<mat-checkbox id="outdoor_field_trip" [formControl]="outdoor_field_trip">Outdoor</mat-checkbox>

    <mat-form-field class="example-full-width" *ngIf="outdoor_field_trip.value">
      <mat-label for="outdoor">Outdoor ADMIN NOTES </mat-label>
      <input matInput id="outdoor" type="text" [formControl]="outdoor">
      <mat-hint>This field does NOT show on the public course page.</mat-hint>
    </mat-form-field>

    <br/><br/>

  <mat-checkbox id="av_required" [formControl]="av_required">AV Required</mat-checkbox>

  <mat-form-field class="example-full-width" *ngIf="av_required.value">
    <mat-label for="av_checklist">AV Checklist </mat-label>
    <textarea rows="3" matInput id="av_checklist" type="text" [formControl]="av_checklist"></textarea>
    <mat-hint>EX: computer, adapter (Apple/PC), projector, mic system, white board, DVD player, internet, PowerPoint, jump drive, tech support, which sessions need A/V</mat-hint>
  </mat-form-field>
<br/><br/>


<mat-form-field class="example-full-width">
  <mat-label for="special_requests">Phone # and Special Requests </mat-label>
  <input matInput id="special_requests" type="text" [formControl]="special_requests">
  <mat-hint>This field does NOT show on the public course page.</mat-hint>
</mat-form-field>
<br/><br/>


  <mat-form-field class="example-full-width">
    <mat-label for="photo">Photo </mat-label>
    <input matInput id="photo" type="text" [formControl]="photo">
    <mat-hint>https://lh3.googleusercontent.com/d/<b>[file ID]</b> --- <i>200px max height</i></mat-hint>
  </mat-form-field>
  <br/><br/>


  <mat-checkbox id="concept" [formControl]="concept"><b>CONCEPT</b> </mat-checkbox> (unselect checkbox to move into a semester)
    <br/>
    <mat-form-field *ngIf="!concept.value">
        <mat-label>Semester</mat-label>
        <mat-select [formControl]="semesterSelectFormControl" [(value)]="semesterid_selected">
          <mat-option *ngFor="let semester of semester_list" [value]="semester['_id']">
            {{semester['title']}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <br/>
      <button mat-raised-button color="primary" type="button" (click)="updateCourse()">Update</button>
</form>

  </mat-expansion-panel>
</mat-accordion>

</div>

<div *ngIf="lightningService.role.view_admin_concepts && !lightningService.role.update_concepts || lightningService.role.view_admin_concepts && concept.value">
  <br>
  <mat-icon matTooltip="(1) Idea with no facilitator" class="status-icon" *ngIf="status_number.value == '1'"  >lightbulb_outline</mat-icon>
  <mat-icon matTooltip="(2) Facilitator suggested" class="status-icon" *ngIf="status_number.value == '2'"  >thumb_up</mat-icon>
  <mat-icon matTooltip="(3) Proposal submitted" class="status-icon" *ngIf="status_number.value == '3'" >assignment</mat-icon>
  <mat-icon matTooltip="(4) Facilitator response requested" class="status-icon" *ngIf="status_number.value == '4'" >date_range</mat-icon>
  <mat-icon matTooltip="(5) Transfer to semester page" class="status-icon" *ngIf="status_number.value == '5'">done</mat-icon>
  <mat-icon matTooltip="(6) Final confirmation received" class="status-icon" *ngIf="status_number.value == '6'"  >done_all</mat-icon>
  <mat-icon matTooltip="(7) Course cancelled" class="status-icon" *ngIf="status_number.value == '7'" >cancel</mat-icon>
  <br/>
  <b>Assignee:</b> {{ assignee.value }}
  <br/>
  <span *ngIf="facilitator_name.value" ><b>Facilitator:</b></span> <span *ngIf="!facilitator_name.value" style="color:red;"><b>Facilitator:</b></span> {{ facilitator_name.value }}
  <br/>
  <b>Facilitator Email:</b> {{ facilitator_email.value }}
  <br/>
  <b>Course Description:</b> {{ description.value }}
  <br/>
  <b>Facilitator Bio:</b> {{ facilitator_bio.value }}
  <br/>
  <b>Shepherd:</b> {{ shepherd_name.value }}
  <br/>
  <span *ngIf="first_aid_user_id.value" style="color:purple;"><b>First Aid Provider:</b> <span style="color:black;">&nbsp; <a href="/admin-user-detail/{{first_aid_user_id.value}}">View Profile</a></span> <br/></span>
 
  <b>Subject Category:</b>&nbsp;
  <span *ngIf="subject_category.value == 'Art, Music & Dance'">[01]</span>
  <span *ngIf="subject_category.value == 'Literature, Film & Theater'">[02]</span>
  <span *ngIf="subject_category.value == 'History & Current Affairs'">[03]</span>
  <span *ngIf="subject_category.value == 'Science & Nature'">[04]</span>
  <span *ngIf="subject_category.value == 'Technology'">[05]</span>
  <span *ngIf="subject_category.value == 'Health & Fitness'">[06]</span>
  <span *ngIf="subject_category.value == 'Outdoor Experience'">[07]</span>
  <span *ngIf="subject_category.value == 'Out-of-Town Excursions'">[08]</span>
  <span *ngIf="subject_category.value == 'Armchair Travel'">[09]</span>
  <span *ngIf="subject_category.value == 'Self Discovery'">[10]</span>
  <span *ngIf="subject_category.value == 'Cooking & Food'">[11]</span>
  <span *ngIf="subject_category.value == 'Games'">[12]</span>
  <span *ngIf="subject_category.value == 'How To/Everything Else'">[13]</span>
  <span *ngIf="subject_category.value == 'Local Issues & Adventures'">[14]</span>
  {{ subject_category.value }}
  <br/>
  <b>Semester Requested:</b> {{ semester_request.value }}
  <br/>
  <b>Preferred Schedule Requested:</b> {{ preferred_schedule_request_text.value }}
  <br/>
  <b>Sessions/Date/Time:</b> {{ notes.value }}
  <br/>
  <b>First Day of Class:</b> {{start_date.value  | date: 'mediumDate'}}
  <br/>
  <b>Admin Notes:</b> {{ facilitator_shepherd_requested.value }}
  <br/>
  <b>Max Capacity:</b> {{ capacity.value }}
  <br/>
  <b>Min Capacity:</b> {{ minimum_capacity.value }}
  <br/>
  <b>Fees:</b> {{ fees.value }}
  <br/>
  <b>Fees Description:</b> {{ fees_description.value }}
  <br/>
  <b>Location:</b> {{ location.value }}
  <br/>
  <b>Location Type:</b> {{ location_type.value }}
  <br/>
  <b>Outdoor?:</b> {{ outdoor_field_trip.value }}
  <br/>
  <b>Outdoor Admin Notes:</b> {{ outdoor.value }}
  <br/>
  <b>AV Required?:</b> {{ av_required.value }}
  <br/>
  <b>AV Checklist:</b> {{ av_checklist.value }}
  <br/>
  <b>Phone # and Special Requests:</b> {{ special_requests.value }}
  <br/>
  <b>Concept?:</b> {{ concept.value }}
</div>


<div *ngIf="lightningService.role.view_admin_concepts && lightningService.role.update_concepts && !concept.value">
  <br/>
<mat-accordion>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        VIEW FIELDS
      </mat-panel-title>
      <mat-panel-description>
&nbsp;
        <mat-icon class="icon-size">visibility</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-icon matTooltip="(1) Idea with no facilitator" class="status-icon" *ngIf="status_number.value == '1'"  >lightbulb_outline</mat-icon>
    <mat-icon matTooltip="(2) Facilitator suggested" class="status-icon" *ngIf="status_number.value == '2'"  >thumb_up</mat-icon>
    <mat-icon matTooltip="(3) Proposal submitted" class="status-icon" *ngIf="status_number.value == '3'" >assignment</mat-icon>
    <mat-icon matTooltip="(4) Facilitator response requested" class="status-icon" *ngIf="status_number.value == '4'" >date_range</mat-icon>
    <mat-icon matTooltip="(5) Transfer to semester page" class="status-icon" *ngIf="status_number.value == '5'">done</mat-icon>
    <mat-icon matTooltip="(6) Final confirmation received" class="status-icon" *ngIf="status_number.value == '6'"  >done_all</mat-icon>
    <mat-icon matTooltip="(7) Course cancelled" class="status-icon" *ngIf="status_number.value == '7'" >cancel</mat-icon>
    <br/>
    <b>Assignee:</b> {{ assignee.value }}
    <br/>
    <span *ngIf="facilitator_name.value" ><b>Facilitator:</b></span> <span *ngIf="!facilitator_name.value" style="color:red;"><b>Facilitator:</b></span> {{ facilitator_name.value }}
    <br/>
    <b>Facilitator Email:</b> {{ facilitator_email.value }}
    <br/>
    <b>Course Description:</b> {{ description.value }}
    <br/>
    <b>Facilitator Bio:</b> {{ facilitator_bio.value }}
    <br/>
    <b>Shepherd:</b> {{ shepherd_name.value }}
    <br/>
    <span *ngIf="first_aid_user_id.value" style="color:purple;"><b>First Aid Provider:</b> <span style="color:black;">&nbsp; <a href="/admin-user-detail/{{first_aid_user_id.value}}">View Profile</a></span><br/></span>
    
    <b>Subject Category:</b> &nbsp;
    <span *ngIf="subject_category.value == 'Art, Music & Dance'">[01]</span>
    <span *ngIf="subject_category.value == 'Literature, Film & Theater'">[02]</span>
    <span *ngIf="subject_category.value == 'History & Current Affairs'">[03]</span>
    <span *ngIf="subject_category.value == 'Science & Nature'">[04]</span>
    <span *ngIf="subject_category.value == 'Technology'">[05]</span>
    <span *ngIf="subject_category.value == 'Health & Fitness'">[06]</span>
    <span *ngIf="subject_category.value == 'Outdoor Experience'">[07]</span>
    <span *ngIf="subject_category.value == 'Out-of-Town Excursions'">[08]</span>
    <span *ngIf="subject_category.value == 'Armchair Travel'">[09]</span>
    <span *ngIf="subject_category.value == 'Self Discovery'">[10]</span>
    <span *ngIf="subject_category.value == 'Cooking & Food'">[11]</span>
    <span *ngIf="subject_category.value == 'Games'">[12]</span>
    <span *ngIf="subject_category.value == 'How To/Everything Else'">[13]</span>
    <span *ngIf="subject_category.value == 'Local Issues & Adventures'">[14]</span>
    {{ subject_category.value }}
    <br/>
    <b>Semester Requested:</b> {{ semester_request.value }}
    <br/>
    <b>Preferred Schedule Requested:</b> {{ preferred_schedule_request_text.value }}
    <br/>
    <b>Sessions/Date/Time:</b> {{ notes.value }}
    <br/>
    <b>First Day of Class:</b> {{start_date.value  | date: 'mediumDate'}}
    <br/>
    <b>Admin Notes:</b> {{ facilitator_shepherd_requested.value }}
    <br/>
    <b>Max Capacity:</b> {{ capacity.value }}
    <br/>
    <b>Min Capacity:</b> {{ minimum_capacity.value }}
    <br/>
    <b>Fees:</b> {{ fees.value }}
    <br/>
    <b>Fees Description:</b> {{ fees_description.value }}
    <br/>
    <b>Location:</b> {{ location.value }}
    <br/>
    <b>Location Type:</b> {{ location_type.value }}
    <br/>
    <b>Outdoor?:</b> {{ outdoor_field_trip.value }}
    <br/>
    <b>Outdoor Admin Notes:</b> {{ outdoor.value }}
    <br/>
    <b>AV Required?:</b> {{ av_required.value }}
    <br/>
    <b>AV Checklist:</b> {{ av_checklist.value }}
    <br/>
    <b>Phone # and Special Requests:</b> {{ special_requests.value }}
    <br/>
    <b>Concept?:</b> {{ concept.value }}
</mat-expansion-panel>
</mat-accordion>

</div>
<button *ngIf="lightningService.role.delete_concepts" style="margin-top:35px;" mat-raised-button color="warn" type="button" (click)="openDeleteConfirmDialog()">Delete <span *ngIf="concept.value">CONCEPT&nbsp;</span> <span *ngIf="!concept.value">COURSE&nbsp;</span></button>

<div *ngIf="lightningService.role.view_course_members">
    <div class="graybar" *ngIf="!concept.value"> STUDENT LIST
    </div>

    <p style="text-align:right;padding-top:10px;"><b>{{ coursemember_list.length }}</b> total sign ups 
    </p>
    
    <mat-divider></mat-divider>
    <br/>

  <div *ngFor="let coursemember of coursemember_list">
      <table > 
        <tr>
          <th rowspan="3" *ngIf="coursemember['attending']"><mat-icon class="attendwait" matListItemIcon style="color:green;" >check_circle</mat-icon>
          </th>
          <th rowspan="3" *ngIf="coursemember['standby']"><mat-icon class="attendwait" matListItemIcon style="color:orange;" >schedule</mat-icon>
          </th>
          <td>{{ coursemember['user_title'] }}</td>
            <th rowspan="3" *ngIf="lightningService.role.view_admin_users_interface">
              <button mat-raised-button color="primary" type="button" routerLink="/admin-user-detail/{{ coursemember['user_id'] }}">Profile</button>
            </th>
            <th rowspan="3" *ngIf="lightningService.role.delete_course_members">
              <button mat-raised-button color="warn" type="button" (click)="openDeleteCoursememberConfirmDialog(coursemember['_id'])">Remove</button>
            </th>
        </tr>
        <tr>
          <td>{{ coursemember['created'] }}</td>
        </tr>
        <tr>
          <td>{{ coursemember['user_email'] }} </td>
        </tr>
      </table>
  </div>

<br/><br/>
<mat-divider></mat-divider>
<br/><br/>
<b>Enrolled Students Email List:</b>
  <div *ngFor="let coursemember of coursemember_list">
    <div *ngIf="coursemember['attending']">
    "{{ coursemember['user_title'] }}" &lt;{{ coursemember['user_email'] }}&gt;,
    </div>
  </div>
<br/>
  <b>Waitlisted Students Email List:</b>
  <div *ngFor="let coursemember of coursemember_list">
    <div *ngIf="coursemember['standby']">
    "{{ coursemember['user_title'] }}" &lt;{{ coursemember['user_email'] }}&gt;,
    </div>
  </div>

</div>
    <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>