import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-admin-card-create',
  templateUrl: './admin-card-create.component.html',
  styleUrls: ['./admin-card-create.component.css']
})
export class AdminCardCreateComponent {
  constructor(private lightningService: LightningService, private route: ActivatedRoute, private router: Router) { }

  incoming_slugify_uri ="";

  // get the variable out of the url bar
  ngOnInit(): void {
    console.log('card create init');
    
    this.route.paramMap.subscribe((params) => {
      console.log('card data params changed')
      this.incoming_slugify_uri = params.get('slugify_url') || "";
      console.log(this.incoming_slugify_uri);
      //this.role = this.productService.getProduct(this.id);
      //this.getCourseData();

    });
  }

  title = new FormControl('');
  slugify_uri = new FormControl('');
  html_safe_text = new FormControl('');
  header_image = new FormControl('');
  order = new FormControl('');

  createCard = async () => {
    console.log('create card');
    console.log(localStorage.getItem("token"));

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'title':this.title.value,
        'slugify_uri': this.incoming_slugify_uri,
        'html_safe_text': this.html_safe_text.value,
        'header_image': this.header_image.value,
        'order': this.order.value
      
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/cards/create', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        // we dont have to do anything special here.  redirect?
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }

        this.router.navigate(['' ]);

        console.log(data);

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

}
