
<div class="toolbarheader" >
   CREATE A NEW ROLE
  </div>
<br/><br/>
<form #roleCreateForm="ngForm">
    
    <mat-form-field>
        <mat-label for="title">Title</mat-label>
        <input matInput id="title" type="text" [formControl]="title">
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label for="description">Description </mat-label>
        <textarea matInput id="description" type="text" [formControl]="description"></textarea>
      </mat-form-field>

      <div class="header">HTML</div>
      <mat-hint>This permission should only be assigned to HTML-savvy people.</mat-hint>
      <br/>
      <mat-checkbox id="update_pages" [formControl]="update_pages">Update <b>ALL</b> Public Pages HTML</mat-checkbox>
      <br>

      <div class="header">Cards</div>
      <mat-checkbox id="create_card" [formControl]="create_card">Select ▼</mat-checkbox>
      <br>
      
      <div *ngIf="create_card.value">
      <mat-checkbox id="create_eg" [formControl]="create_eg">Create/Edit/Delete <b>Enthusiast Groups</b> Cards</mat-checkbox>
      <br>
      
      <mat-checkbox id="create_lunchlearn" [formControl]="create_lunchlearn">Create/Edit/Delete <b>Lunch & Learn</b> Cards</mat-checkbox>
      <br>
      
      <mat-checkbox id="create_programs" [formControl]="create_programs">Create/Edit/Delete <b>Program</b> Cards</mat-checkbox>
      <br>
      
      <mat-checkbox id="create_news" [formControl]="create_news">Create/Edit/Delete <b>News</b> Cards</mat-checkbox>
      <br>
      
      <mat-checkbox id="create_volunteer" [formControl]="create_volunteer">Create/Edit/Delete <b>Opportunities</b> Cards</mat-checkbox>
      <br>
      </div>
      



<div class="header">Admin</div>

<mat-checkbox id="view_admin_interface" [formControl]="view_admin_interface">Access Admin Menu ▼</mat-checkbox>
<br>

<div *ngIf="view_admin_interface.value">

<div class="header">Roles</div>
<mat-checkbox id="view_admin_roles_interface" [formControl]="view_admin_roles_interface">View Roles List</mat-checkbox>
<br>
<mat-checkbox id="create_roles" [formControl]="create_roles">Create Roles</mat-checkbox>
<br>
<mat-checkbox id="update_roles" [formControl]="update_roles">Edit Roles</mat-checkbox>
<br>
<mat-checkbox id="delete_roles" [formControl]="delete_roles">Delete Roles</mat-checkbox>
<br>

<div class="header">Users</div>
<mat-checkbox id="view_admin_users_interface" [formControl]="view_admin_users_interface">View Users</mat-checkbox>
<br>
<mat-checkbox id="update_users" [formControl]="update_users">Edit Users</mat-checkbox>
<br>
<mat-checkbox id="delete_users" [formControl]="delete_users">Delete Users</mat-checkbox>
<br>

<div class="header">Shepherds</div>
<mat-checkbox id="view_shepherd_resources" [formControl]="view_shepherd_resources">View Shepherd Resources Page</mat-checkbox>
<br>

<div class="header">Concepts & Courses</div>
<mat-checkbox id="view_admin_concepts" [formControl]="view_admin_concepts">View Concepts/Courses</mat-checkbox>
<br>
<mat-checkbox id="create_concepts" [formControl]="create_concepts">Create Concepts</mat-checkbox>
<br>
<mat-checkbox id="update_concepts" [formControl]="update_concepts">Edit Concepts/Courses</mat-checkbox>
<br>
<mat-checkbox id="delete_concepts" [formControl]="delete_concepts">Delete Concepts/Courses</mat-checkbox>
<br>

<div class="header">Semesters</div>
<mat-checkbox id="view_admin_semesters" [formControl]="view_admin_semesters">View Semesters</mat-checkbox>
<br>
<mat-checkbox id="create_semesters" [formControl]="create_semesters">Create New Semesters</mat-checkbox>
<br>
<mat-checkbox id="update_semesters" [formControl]="update_semesters">Edit Semesters</mat-checkbox>
<br>

<div class="header">Course Members</div>

<mat-hint>Course Members are enrolled & waitlisted students for a course.</mat-hint>
<br/>
<mat-checkbox id="view_course_members" [formControl]="view_course_members">View Course Members</mat-checkbox>
<br>
<mat-checkbox id="delete_course_members" [formControl]="delete_course_members">Remove Course Members</mat-checkbox>
<br>
</div>



</form>
<br><br>
    <button mat-raised-button color="primary" type="button" (click)="createRole()">Create</button>
