import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { LightningService } from '../lightning.service';
import { DeleteConfirmDialogComponent } from '../delete-confirm-dialog/delete-confirm-dialog.component'
import { GenericResponseDialogComponent } from '../generic-response-dialog/generic-response-dialog.component'

@Component({
  selector: 'app-admin-card-detail',
  templateUrl: './admin-card-detail.component.html',
  styleUrls: ['./admin-card-detail.component.css']
})
export class AdminCardDetailComponent {
  constructor(public lightningService: LightningService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) { }

  cardid ="";
  public card_slugify_url = "";
  public card = {};

  // load the card into the form
  ngOnInit(): void {
    console.log('page update init');

    this.route.paramMap.subscribe((params) => {
      console.log('role data params changed')
      this.cardid = params.get('cardid') || "";
      this.getCardData();

    });



  }

  getCardData = async () => {
    if (localStorage.getItem("token") === null) {
      console.log('card data - no access token');
    }
    else
    {
      const response = await fetch(this.lightningService.apiUrl + '/admin/cards/' + this.cardid, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      console.log(result.card);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
      if (result.card) {
        console.log('found card');
        console.log(result.card['title'])
        this.card = result.card;

        //this.pageid = result.page['_id'];

        this.title.setValue(result.card['title']);
        this.slugify_uri.setValue(result.card['slugify_uri']);

        this.html_safe_text.setValue(result.card['html_safe_text']);
        this.header_image.setValue(result.card['header_image']);
        this.order.setValue(result.card['order']);

      }
    }
  }



  title = new FormControl('');
  slugify_uri = new FormControl('');
  html_safe_text = new FormControl('');
  header_image = new FormControl('');
  order = new FormControl('');

  updateCard = async () => {
    console.log('update card');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'_id':this.cardid,
         'title':this.title.value,
        'slugify_uri': this.slugify_uri.value,
        'html_safe_text': this.html_safe_text.value,
        'header_image': this.header_image.value,
        'order': this.order.value
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/cards/' + this.cardid + '/update', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }

        // we dont have to do anything special here.  redirect?

        this.dialog.open(GenericResponseDialogComponent, {
          data: {
            response_message: 'Your changes have been saved.',
            response_title: 'Success!'
          },
        });
        console.log(data);

        return data;
    //} catch (e) {
    //    return e;
    //}

  }

  openDeleteConfirmDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {title: "Confirm Deletion", description: "Are you sure you want to delete this card? This action cannot be undone."},
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('The dialog was closed');
      //this.animal = result;
      if (data) {
        console.log('Confirm was pressed');
        this.deleteCard();
      }
    });
  }

  deleteCard = async () => {
    console.log('delete card');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'_id':this.cardid})
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/cards/' + this.cardid + '/delete', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?

        this.router.navigate(['' ]);
        console.log(data);

        return data;
    //} catch (e) {
    //    return e;
    //}

  }

}
