import { Component } from '@angular/core';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-admin-roles',
  templateUrl: './admin-roles.component.html',
  styleUrls: ['./admin-roles.component.css']
})
export class AdminRolesComponent {
  constructor(public lightningService: LightningService) { }
  public role_list = [];

  ngOnInit(): void {
    console.log('profile init');
    //this.title.setValue(this.lightningService.user['title']);
    this.getRoles();

  }

  getRoles = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/roles/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);

      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
 
      this.role_list = result;
  
    }

}
