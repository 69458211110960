<div class="toolbarheader" >
    COURSE DETAILS
    </div>
<br/>
<img src="{{ course_photo }}" alt="course photo" class="responsive" />

<h1> {{ order }}. {{ course_title }} </h1>
<i>{{ subject_category }}</i><br/>
{{ facilitator_name }} <br/>
{{ course_notes }}<br/>
<b>{{ signups }}</b> students signed up for <b>{{ capacity }}</b> total seats.
<ng-container *ngIf="fees"><br/><b>Fee:</b> ${{ fees }}</ng-container><ng-container *ngIf="fees_description"> --- <b>Fee Details:</b> {{ fees_description }}</ng-container>
<br/><br/>


<p>{{ course_description }}</p>

<p><b>Facilitator Bio: </b>{{ facilitator_bio }}</p>







