import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';

import { LightningService } from '../lightning.service';
import {MatDialog, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import { GenericResponseDialogComponent } from '../generic-response-dialog/generic-response-dialog.component';
import { DeleteConfirmDialogComponent } from '../delete-confirm-dialog/delete-confirm-dialog.component'


@Component({
  selector: 'app-admin-course-detail',
  templateUrl: './admin-course-detail.component.html',
  styleUrls: ['./admin-course-detail.component.css']
})
export class AdminCourseDetailComponent {
  constructor(public lightningService: LightningService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) { }

  courseid ="";
  public course = {};
  public semester_list = [];
  public semesterid_selected = "";
  public coursemember_list = [];

  // load the role into the form
  ngOnInit(): void {
    console.log('course detail init');

    this.route.paramMap.subscribe((params) => {
      console.log('course data params changed')
      this.courseid = params.get('courseid') || "";
      //this.role = this.productService.getProduct(this.id);
      this.getCourseData();

    });
  }

  getCourseData = async () => {
    if (localStorage.getItem("token") === null) {
      console.log('course data - no access token');
    }
    else
    {
      const response = await fetch(this.lightningService.apiUrl + '/admin/courses/' + this.courseid, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      console.log(result.course);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }

      if (result.course) {
        console.log('found course');
        console.log(result.course['title'])
        console.log(result.course['description'])

        this.course = result.course;
        this.title.setValue(result.course['title']);
        this.description.setValue(result.course['description']);
        this.created = new Date(result.course['created'].$date);
        this.updated = new Date(result.course['updated'].$date);

        this.notes.setValue(result.course['notes']);
        this.facilitator_bio.setValue(result.course['facilitator_bio']);
        this.minimum_capacity.setValue(result.course['minimum_capacity']);
        this.special_requests.setValue(result.course['special_requests']);
        this.preferred_schedule_request_text.setValue(result.course['preferred_schedule_request_text']);
        this.facilitator_shepherd_requested.setValue(result.course['facilitator_shepherd_requested']);
        this.capacity.setValue(result.course['capacity']);
        this.subject_category.setValue(result.course['subject_category']);
        this.photo.setValue(result.course['photo']);

        this.facilitator_id.setValue(result.course['facilitator_id']);
        this.facilitator_name.setValue(result.course['facilitator_name']);
        this.facilitator_email.setValue(result.course['facilitator_email']);

        this.shepherd_id.setValue(result.course['shepherd_id']);
        this.shepherd_name.setValue(result.course['shepherd_name']);

        this.fees.setValue(result.course['fees']);
        this.fees_description.setValue(result.course['fees_description']);

        this.status_number.setValue(result.course['status_number']);

        this.submittedby_user_title = result.course['submittedby_user_title'];
        this.submittedby_user_id = result.course['submittedby_user_id'].$oid;

        this.assignee.setValue(result.course['assignee']);

        this.order.setValue(result.course['order']);
        this.ready_for_signups.setValue(result.course['ready_for_signups']);

        this.av_required.setValue(result.course['av_required']);
        this.av_checklist.setValue(result.course['av_checklist']);
        this.outdoor.setValue(result.course['outdoor']);
        this.outdoor_field_trip.setValue(result.course['outdoor_field_trip']);
        this.semester_request.setValue(result.course['semester_request']);
        this.location.setValue(result.course['location']);
        this.location_type.setValue(result.course['location_type']);
        this.concept.setValue(result.course['concept']);


        this.semesterid_selected = result.course['semester_id'];

        //  check to see if the date is blank.  
        // If so it will default to today
        // If false, use the date supplied
        if (!result.course['start_date'])
        {
          this.start_date.setValue( null );
        }
        else{
          this.start_date.setValue(new Date(result.course['start_date']) );
        }
        


        this.first_aid_user_id.setValue(result.course['first_aid_user_id']);

        this.getSemesters();
        this.getCoursemembers();

      }
    }
  }

  getSemesters = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/semesters/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      // we need to check the permission first.
      if (this.lightningService.role.view_admin_semesters)
        {
          const result = await response.json();
          console.log(result);
          if (result.status == "401")
          {
            this.lightningService.requestLogin();
          }
    
          this.semester_list = result;
        }
      

    }

  getCoursemembers = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/courses/' + this.courseid + '/members', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      // we need to check the permission first.
      if (this.lightningService.role.view_course_members)
        {

      const result = await response.json();
      console.log(result);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }

      this.coursemember_list = result;
    }

    }

  // datepicker defaults
  today = new Date();
  default_month = this.today.getMonth();
  default_year = this.today.getFullYear();

  // this is the date stuff
  created = new Date();
  updated = new Date();

  title = new FormControl('');
  description = new FormControl('');
  notes = new FormControl('');
  facilitator_bio = new FormControl('');
  minimum_capacity = new FormControl('');
  special_requests = new FormControl('');
  preferred_schedule_request_text = new FormControl('');
  facilitator_shepherd_requested = new FormControl('');
  capacity = new FormControl('');
  subject_category = new FormControl('');
  photo = new FormControl('');

  facilitator_id = new FormControl('');
  facilitator_name = new FormControl('');
  facilitator_email = new FormControl('');

  shepherd_id = new FormControl('');
  shepherd_name = new FormControl('');

  fees = new FormControl('');
  fees_description = new FormControl('');

  status_number = new FormControl('');


  submittedby_user_title = "";
  submittedby_user_id = "";
  assignee = new FormControl('');

  order = new FormControl('');
  ready_for_signups = new FormControl('');

  av_required = new FormControl('');
  av_checklist = new FormControl('');
  outdoor = new FormControl('');
  outdoor_field_trip = new FormControl('');
  semester_request = new FormControl('');
  location = new FormControl('');
  location_type = new FormControl('');
  concept = new FormControl('');

  semesterSelectFormControl = new FormControl('');

  first_aid_user_id = new FormControl('');


  start_date = new FormControl(new Date(this.default_year, this.default_month, 13));



  updateCourse = async () => {
    console.log('update course');
    console.log(localStorage.getItem("token"));
    console.log(this.semesterSelectFormControl.value);

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'_id':this.courseid,
         'title':this.title.value,
        'description': this.description.value,

        'notes': this.notes.value,
        'facilitator_bio': this.facilitator_bio.value,
        'minimum_capacity': this.minimum_capacity.value,
        'special_requests': this.special_requests.value,
        'preferred_schedule_request_text': this.preferred_schedule_request_text.value,
        'facilitator_shepherd_requested': this.facilitator_shepherd_requested.value,
        'capacity': this.capacity.value,
        'subject_category': this.subject_category.value,
        'photo': this.photo.value,

        'facilitator_id': this.facilitator_id.value,
        'facilitator_name': this.facilitator_name.value,
        'facilitator_email': this.facilitator_email.value,

        'shepherd_id': this.shepherd_id.value,
        'shepherd_name': this.shepherd_name.value,

        'fees': this.fees.value,
        'fees_description': this.fees_description.value,

        'status_number': this.status_number.value,
        'assignee': this.assignee.value,

        'order': this.order.value,
        'ready_for_signups': this.ready_for_signups.value,

        'av_required': this.av_required.value,
        'av_checklist': this.av_checklist.value,
        'outdoor': this.outdoor.value,
        'outdoor_field_trip': this.outdoor_field_trip.value,
        'semester_request': this.semester_request.value,
        'location': this.location.value,
        'location_type': this.location_type.value,
        'concept': this.concept.value,
        'semester_id': this.semesterSelectFormControl.value,
        'start_date': this.start_date.value,
        'end_date': this.start_date.value,

        'first_aid_user_id': this.first_aid_user_id.value

                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/courses/' + this.courseid + '/update', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        console.log(data);

        this.dialog.open(GenericResponseDialogComponent, {
          data: {
            response_message: 'Your changes have been saved.',
            response_title: 'Success!'
          },
        });
        this.getCourseData();

        return data;
    //} catch (e) {
    //    return e;
    //}

  }

  openDeleteConfirmDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {title: "Confirm Deletion", description: "Are you sure you want to delete this course? This action cannot be undone."},
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('The dialog was closed');
      //this.animal = result;
      if (data) {
        console.log('Confirm was pressed');
        this.deleteCourse();
      }
    });
  }

  deleteCourse = async () => {
    console.log('delete course');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'_id':this.courseid})
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/courses/' + this.courseid + '/delete', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        console.log(data);
        this.router.navigate(['admin-courses' ]);

        return data;
    //} catch (e) {
    //    return e;
    //}

  }

  openDeleteCoursememberConfirmDialog(coursemember_id: string): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {title: "Confirm Removal", description: "Are you sure you want to remove this student? This action cannot be undone."},
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('The dialog was closed');
      //this.animal = result;
      if (data) {
        console.log('Confirm was pressed');
        this.deleteCoursemember(coursemember_id);
      }
    });
  }

  deleteCoursemember = async (coursemember_id: string) => {
    console.log('delete coursemember');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'_id':coursemember_id})
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/coursemembers/' + coursemember_id + '/delete', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }

        console.log(data);
      //  window.location.reload();
        //this.router.navigate(['admin-course-detail', this.courseid ]);
        this.getCoursemembers();


        return data;
    //} catch (e) {
    //    return e;
    //}

  }

}
