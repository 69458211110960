<p>admin-page-create works!</p>

<form #semesterCreateForm="ngForm">
    <label for="title">Name: </label>
    <input id="title" type="text" [formControl]="title">

    <label for="slugify_uri">slugify_uri: </label>
    <input id="slugify_uri" type="text" [formControl]="slugify_uri">

    <label for="html_safe_text">html_safe_text: </label>
    <input id="html_safe_text" type="text" [formControl]="html_safe_text">

      
</form>

<button mat-raised-button color="primary" type="button" (click)="createPage()">Create</button>