import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { LightningService } from '../lightning.service';
import { Router, ActivatedRoute} from '@angular/router';

import {MatDialog, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import { GenericResponseDialogComponent } from '../generic-response-dialog/generic-response-dialog.component';

@Component({
  selector: 'app-semester-signup',
  templateUrl: './semester-signup.component.html',
  styleUrls: ['./semester-signup.component.css']
})
export class SemesterSignupComponent {
  constructor(public lightningService: LightningService, private fb: FormBuilder, public dialog: MatDialog, private router: Router) { }

  public course_list = [];
  selectedCourses : string[] = [];
  public signups_active :boolean = false;


  
 
  ngOnInit(): void {
    console.log('semester detail init');
    this.getActiveSemesterCourses();

  }


  getActiveSemesterCourses = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/semesters/active/courses', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
  
      this.course_list = result.courses;
      this.signups_active = result.semester['signups_active'];
  
    }

  checkboxChange(event: any, courseid: string) {
    console.log('add course');
    console.log(courseid)
    if (event.checked)
    {
      console.log('it is checked');
      this.selectedCourses.push(courseid);
    }
    else
    {
      console.log('it is not checked');
      const index = this.selectedCourses.indexOf(courseid);
      const x = this.selectedCourses.splice(index, 1)
    }
    
    
  }

  submitSignup = async () => {
    console.log('submit signup');
    console.log(localStorage.getItem("token"));

    var selectedCourseDict = [];

    for (var i = 0; i < this.selectedCourses.length; i++) {
      console.log('looping over selectedCourses')
      selectedCourseDict.push({
          id:   this.selectedCourses[i]
      });
    }

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'courses':selectedCourseDict
         
      
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/semesters/active/signup', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        //this.router.navigate(['admin-users']);

        console.log(data);

        // Open a generic dialog.
        // You need the 2 lines of imports at the top
        // and matDialog in the constructor
        this.dialog.open(GenericResponseDialogComponent, {
          data: {
            response_message: data['response_message'], 
            response_title: data['response_title']
          },
        });

        this.router.navigate(['enrollment-list' ]);

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

}
