import { Component } from '@angular/core';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.css']
})
export class CourseListComponent {

  constructor(public lightningService: LightningService) { }
  public semester = {'title':""};
  courses = [];

  title = "";
  html_safe_text = "";
  header_image = "";

  // init
  ngOnInit(): void {
    console.log('course list init');
  
    this.getCourseListData();
    this.getPageData();
     
  }

  getCourseListData = async () => {

      const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      const response = await fetch(this.lightningService.apiUrl + '/semesters/active/courses', settings);
      const result = await response.json();
      console.log(result);
      console.log(result.courses); 
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
      if (result.courses) {
        console.log('found courses');

        this.courses = result.courses;
    

      }
      if (result.semester) {
        console.log('found semester');
        this.semester = result.semester;
      }
  }

  getPageData = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/pages/courses');
    const result = await response.json();
    console.log(result);
    console.log(result.page); 
    if (result.page) {
      console.log('found page');
      console.log(result.page['title'])
      this.title = result.page['title']
      this.html_safe_text = result.page['html_safe_text']
      this.header_image = result.page['header_image']
    }
  }

}
