
<div class="toolbarheader" >
    SEMESTERS

    <span class="spacer"></span>
    <button *ngIf="lightningService.role.create_semesters" class="button-style" routerLink="/admin-semester-create"><mat-icon class="add">add</mat-icon></button>

</div>



  <mat-divider></mat-divider>
<mat-list>

    <mat-nav-list>
        <mat-list-item *ngFor="let semester of semester_list" routerLink="/admin-semester-detail/{{ semester['_id'] }}">
            
            <button *ngIf="semester['active']" mat-icon-button aria-label="semester status">
				<mat-icon mat-list-icon  class="mat-list-icon-active">event_available</mat-icon>
            </button> 
        <button *ngIf="!semester['active']" mat-icon-button matTooltip="disabled" disabled aria-label="semester status">
            <mat-icon mat-list-icon class="mat-list-icon-inactive">close</mat-icon>
        </button>
         <b mat-line class="semestertitles">{{semester['title']}} </b>
            ---
         <i mat-line class="semesterdates">   {{semester['starts'] | date:'MMM dd, yyyy'}} - {{semester['ends'] | date:'MMM dd, yyyy'}}
         </i>

        
        </mat-list-item>
    </mat-nav-list>
    </mat-list>
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>