

<h2>Admin</h2>

<p>Users</p>

<p>Courses</p>

<p>Semesters</p>

<p>admin works!</p>
