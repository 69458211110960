
<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>

    <p>{{ data.description }}</p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()">NEVERMIND</button>
  <span class="spacer"></span>
  <button mat-raised-button color="warn" (click)="yesButtonClicked()" cdkFocusInitial>CONFIRM DELETE</button>
</div>