import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { LightningService } from '../lightning.service';



@Component({
  selector: 'app-admin-course-create',
  templateUrl: './admin-course-create.component.html',
  styleUrls: ['./admin-course-create.component.css']
})
export class AdminCourseCreateComponent {
  constructor(private lightningService: LightningService, private route: ActivatedRoute, private router: Router) { }

  title = new FormControl('');
  assignee = new FormControl('');
  shepherd_id = new FormControl('');
  first_aid_user_id = new FormControl('');
  order = new FormControl('');
  status_number = new FormControl('');
  facilitator_id = new FormControl('');
  facilitator_name = new FormControl('');

  description = new FormControl('');
  notes = new FormControl('');
  facilitator_bio = new FormControl('');
  minimum_capacity = new FormControl('');
  fees = new FormControl('');
  fees_description = new FormControl ('');
  special_requests = new FormControl('');
  preferred_schedule_request_text = new FormControl('');


  facilitator_shepherd_requested = new FormControl('');
  capacity = new FormControl('');
  subject_category = new FormControl('');
  photo = new FormControl('');
  av_required = new FormControl('');
  av_checklist = new FormControl('');
  outdoor = new FormControl('');
  outdoor_field_trip = new FormControl('');
  semester_request = new FormControl('');
  location = new FormControl('');
  location_type = new FormControl('');

  createCourse = async () => {
    console.log('create course');
    console.log(localStorage.getItem("token"));


    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'title':this.title.value,
        'assignee': this.assignee.value,
        'facilitator_id': this.facilitator_id.value,
        'facilitator_name': this.facilitator_name.value,
        'shepherd_id': this.shepherd_id.value,
        'first_aid_user_id': this.first_aid_user_id.value,
        'order': this.order.value,
        'status_number': this.status_number.value,
        'description': this.description.value,
        'notes': this.notes.value,
        'facilitator_bio': this.facilitator_bio.value,
        'minimum_capacity': this.minimum_capacity.value,
        'fees': this.fees.value,
        'fees_description': this.fees_description.value,
        'special_requests': this.special_requests.value,
        'preferred_schedule_request_text': this.preferred_schedule_request_text.value,
        'facilitator_shepherd_requested': this.facilitator_shepherd_requested.value,
        'capacity': this.capacity.value,
        'subject_category': this.subject_category.value,
        'photo': this.photo.value,
        'av_required': this.av_required.value,
        'av_checklist': this.av_checklist.value,
        'outdoor': this.outdoor.value,
        'outdoor_field_trip': this.outdoor_field_trip.value,
        'semester_request': this.semester_request.value,
        'location': this.location.value,
        'location_type': this.location_type.value
      
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/courses/create', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }

        console.log(data);
        this.router.navigate(['admin-courses' ]);

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

}
