import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-admin-page-create',
  templateUrl: './admin-page-create.component.html',
  styleUrls: ['./admin-page-create.component.css']
})
export class AdminPageCreateComponent {
  constructor(private lightningService: LightningService) { }

  title = new FormControl('');
  slugify_uri = new FormControl('');
  html_safe_text = new FormControl('');
  header_image = new FormControl('');

  createPage = async () => {
    console.log('create page');
    console.log(localStorage.getItem("token"));

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'title':this.title.value,
        'slugify_uri': this.slugify_uri.value,
        'html_safe_text': this.html_safe_text.value,
        'header_image': this.header_image.value
      
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/pages/create', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        console.log(data);

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

}
