
<div class="toolbarheader">
  COURSE SIGN UPS
</div>
<br/>

<div *ngIf="!lightningService.user['member']">
  <br/>
  You must have an active <a routerLink="/pages/membership">WILL Membership</a> before signing up for courses.
</div>

<div *ngIf="!signups_active">
<span style="color:red;"><b>Sign-ups are currently closed.</b></span>
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
</div>

  <div *ngIf="signups_active">


<div *ngIf="!lightningService.user['travel_request_active'] || !lightningService.user['liability_waiver_active']">
  <br/>
You must complete your <a routerLink="/profile">Travel & Liability Forms</a> before signing up for courses.
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
</div>


  


<div  *ngIf="lightningService.user['travel_request_active'] && lightningService.user['liability_waiver_active'] && lightningService.user['member']">
  <br/>
  Select the courses you can commit to attend. Then <b>click the SIGN UP button at the bottom of the page</b>. 
  You may resubmit this form as many times as you would like to sign up for more courses during a semester. 
  Visit <a routerLink="/enrollment-list">My Course List</a> anytime to review all the courses you have signed up for.
  <br/> <br/>
  <mat-divider></mat-divider>
  <br/>

<form #roleUpdateeForm="ngForm">

  <div *ngFor="let course of course_list ">
    <table>
      <tr>
        <th rowspan="3">
          <mat-checkbox  name="selectedCourses" value="{{ course['_id'] }}" aria-label="Course" (change)="checkboxChange($event, course['_id'])"></mat-checkbox>
        </th>

        <td> <b>#{{ course['order']}}: {{course['title'] }}</b>
        </td>
      </tr>
      <tr>
        <i>{{ course['notes'] }}</i>
      </tr>
      <tr>
        <td>Limit: {{ course['capacity'] }} | Seats Taken: {{ course['signups'] }}
        </td>
      </tr>
  
    </table>
    </div>


  <br/>
  <button mat-raised-button color="primary" type="button" (click)="submitSignup()">SIGN UP</button>
</form>

</div>
</div>

<br/><br/>