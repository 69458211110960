
<div class="toolbarheader" >
  CONCEPTS
  <span class="spacer"></span>
  <button *ngIf="lightningService.role.create_concepts" class="button-style" routerLink="/admin-course-create"><mat-icon class="add">add</mat-icon></button>

</div>


<p style="text-align:right;padding-top:10px;"><b>{{ concept_course_list.length }}</b> total concepts 
  </p>
  
  <mat-divider></mat-divider>
  <br/>

<div  *ngIf="lightningService.role.view_admin_concepts">
<table routerLink="/admin-course-detail/{{ site_course['_id'] }}" *ngFor="let site_course of concept_course_list">
  <tr>
    <th rowspan="3">
  <mat-icon matTooltip="(1) Idea with no facilitator" class="status-icon" *ngIf="site_course['status_number'] == 1"  >lightbulb_outline</mat-icon>
  <mat-icon matTooltip="(2) Facilitator suggested" class="status-icon" *ngIf="site_course['status_number'] == 2"  >thumb_up</mat-icon>
  <mat-icon matTooltip="(3) Proposal submitted" class="status-icon" *ngIf="site_course['status_number'] == 3"  >assignment</mat-icon>
  <mat-icon matTooltip="(4) Facilitator response requested" class="status-icon" *ngIf="site_course['status_number'] == 4"  >date_range</mat-icon>
  <mat-icon matTooltip="(5) Transfer to semester page" class="status-icon" *ngIf="site_course['status_number'] == 5"  >done</mat-icon>
  <mat-icon matTooltip="(6) Final confirmation received" class="status-icon" *ngIf="site_course['status_number'] == 6"  >done_all</mat-icon>
  <mat-icon matTooltip="(7) Course cancelled" class="status-icon" *ngIf="site_course['status_number'] == 7"  >cancel</mat-icon>
    </th>

    <td matListItemTitle> <b>#{{site_course['order']}}: {{site_course['title']}}</b>
    </td>
  </tr>
  <tr>
    <i>{{site_course['subject_category']}}</i>
  </tr>
  <tr>
    <td>STAT {{site_course['status_number']}} - <b>A:</b> {{site_course['assignee']}} -   <span *ngIf="site_course['facilitator_name']" ><b>F:</b></span> <span *ngIf="!site_course['facilitator_name']" style="color:red;"><b>F:</b></span> {{site_course['facilitator_name']}} -   <span *ngIf="site_course['shepherd_name']" ><b>S:</b></span> <span *ngIf="!site_course['shepherd_name']" style="color:orange;"><b>S:</b></span> {{site_course['shepherd_name']}}
    </td>
  </tr>
</table>
</div>
  



