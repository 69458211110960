<div class="toolbarheader">PROPOSE A COURSE</div>


<div *ngIf="!lightningService.signinSuccessful">
 You must be logged in to submit a course proposal. Click on the LOGIN button on the top right of this page to get started.
 <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
</div>


<div *ngIf="lightningService.signinSuccessful">

  <div *ngIf="!lightningService.user['travel_request_active'] || !lightningService.user['liability_waiver_active']">You must complete your
    <a routerLink="/profile">Travel & Liability Forms on your Profile page</a> before being able to submit a course proposal.
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
  </div>
  <div *ngIf="lightningService.user['travel_request_active'] && lightningService.user['liability_waiver_active']">


<div *ngIf="formsection==1">

    Thank you for your interest in facilitating a course for WILL! Your willingness to volunteer your time & expertise is appreciated.
    <br/><br/>
    Please supply all the information requested on this form.  Click CONTINUE to proceed to the next page (there are 4 pages).
    <br/><br/>
    <span style="color:rgb(151, 10, 10);font-weight:bold;">IMPORTANT!</span> To return to a previous page, only use the GO BACK button at the bottom of each page or you will lose what you've already done.
    <br/><br/>
    Once you've submitted the form, a member of the Curriculum Committee with be in touch.

    <br/>
<br/>
An asterisk (*) indicates a required field.<br/>
<b>DO NOT use your browser's back button.</b>
<br/>
<br/>
    <mat-form-field class="title-width">
        <mat-label for="title">Title </mat-label>
        <input matInput id="title" type="text" [formControl]="title" required maxlength="65">
        <mat-hint>Character limit: <b>65</b> (This field will stop you after 65, so if it fits, you're within the limit)</mat-hint>
        <mat-error *ngIf="title.hasError('required')">Titles are limited to a maximum of 65 characters.<br/>
        <b>You must enter a title.</b></mat-error>
    </mat-form-field>
<br/>
<br/>
<br/>
    <mat-form-field class="example-full-width">
        <mat-label for="description">Course Description </mat-label>
        <textarea rows="5" matInput id="description" type="text" [formControl]="description" required></textarea>
        <mat-hint>Write a paragraph that describes your course. <span style="color:rgb(151, 10, 10);font-weight:bold;">IMPORTANT!</span> We will use your exact wording. Please proofread carefully.</mat-hint>
        <mat-error *ngIf="description.hasError('required')">Please write a paragraph that describes your course. <span style="color:rgb(151, 10, 10);font-weight:bold;">IMPORTANT!</span> We will use your exact wording. Please proofread carefully.<br/>
            <b>You must fill out this field.</b></mat-error>
      </mat-form-field>
      <br/>
      <br/>
      <br/>
      <mat-form-field class="example-full-width">
        <mat-label for="facilitator_bio">Facilitator Bio </mat-label>
        <textarea rows="5" matInput id="facilitator_bio" type="text" [formControl]="facilitator_bio" required></textarea>
        <mat-hint>Write a paragraph that describes your background as related to the course. <span style="color:rgb(151, 10, 10);font-weight:bold;">IMPORTANT!</span> We will use your exact wording. Please proofread carefully.</mat-hint>
        <mat-error *ngIf="facilitator_bio.hasError('required')">Please write a paragraph that describes your background as related to the course. <span style="color:rgb(151, 10, 10);font-weight:bold;">IMPORTANT!</span> We will use your exact wording. Please proofread carefully.<br/>
            <b>You must fill out this field.</b></mat-error>
      </mat-form-field>
      <br/>
      <br/>
      <br/>
      <mat-form-field class="half-width">
        <mat-label for="subject_category">Subject Category </mat-label>
        <mat-select matInput id="subject_category" [formControl]="subject_category" matNativeControl>
          <mat-option value="Art, Music & Dance">Art, Music & Dance</mat-option>
          <mat-option value="Literature, Film & Theater">Literature, Film & Theater</mat-option>
          <mat-option value="History & Current Affairs">History & Current Affairs</mat-option>
          <mat-option value="Science & Nature">Science & Nature</mat-option>
          <mat-option value="Technology">Technology</mat-option>
          <mat-option value="Health & Fitness">Health & Fitness</mat-option>
          <mat-option value="Outdoor Experience">Outdoor Experience</mat-option>
          <mat-option value="Out-of-Town Excursions">Out-of-Town Excursions</mat-option>
          <mat-option value="Armchair Travel">Armchair Travel</mat-option>
          <mat-option value="Self Discovery">Self Discovery</mat-option>
          <mat-option value="Cooking & Food">Cooking & Food</mat-option>
          <mat-option value="Games">Games</mat-option>
          <mat-option value="How To/Everything Else">How To/Everything Else</mat-option>
          <mat-option value="Local Issues & Adventures">Local Issues & Adventures</mat-option>
        </mat-select>
        <mat-hint>Select the best-fitting subject for your course from the drop-down menu above.</mat-hint>
      </mat-form-field>
      <br/>
      <br/>
      <br/>
<button style="margin-right:10px;" mat-raised-button color="primary" (click)="changeFormSection(2)">Continue</button> Page 1 of 4

</div>

</div>

<div *ngIf="formsection==2">

      <span class="header">Preferred schedule for your course:</span><br/>
      The calendar on this page is provided for your reference. It is a visual aid only.
      <br/><br/>
      <mat-card class="demo-inline-calendar-card">
        <mat-calendar></mat-calendar>
      </mat-card>
      <br/>
      <br/>

      <mat-form-field class="half-width">
        <mat-label for="semester_request">Semester Requested </mat-label>
        <input matInput id="semester_request" type="text" [formControl]="semester_request" required>
        <mat-hint>Please specify Spring/Summer/Fall & Year.</mat-hint>
        <mat-error *ngIf="semester_request.hasError('required')">Please specify Spring/Summer/Fall & Year.<br/>
        <b>You must fill out this field.</b></mat-error>
    </mat-form-field>
    <br/>
    <br/>
    <br/>
      <mat-form-field class="third-width">
        <mat-label for="preferred_schedule_request_text1">Total # of sessions </mat-label>
        <input matInput id="preferred_schedule_request_text1" type="text" [formControl]="preferred_schedule_request_text1" required>
        <mat-error *ngIf="preferred_schedule_request_text1.hasError('required')">You must fill out this field.</mat-error>
      </mat-form-field>
      <br/>
      <br/> 
      <mat-form-field class="example-full-width">
        <mat-label for="preferred_schedule_request_text2">Days & Dates </mat-label>
        <textarea matInput id="preferred_schedule_request_text2" type="text" [formControl]="preferred_schedule_request_text2" required></textarea>
        <mat-hint>Example: Mondays and Wednesdays, November 4, 7, 11, 13</mat-hint>
        <mat-error *ngIf="preferred_schedule_request_text2.hasError('required')">Example: Mondays and Wednesdays, November 4, 7, 11, 13<br/>
        <b>You must fill out this field.</b></mat-error>
      </mat-form-field>
      <br/><br/>
      <br/>
      <mat-form-field class="example-full-width">
        <mat-label for="preferred_schedule_request_text3">Specific Times </mat-label>
        <textarea matInput id="preferred_schedule_request_text3" type="text" [formControl]="preferred_schedule_request_text3" required></textarea>
        <mat-hint>Example: 11:00 AM - 1:00 PM</mat-hint>
        <mat-error *ngIf="preferred_schedule_request_text3.hasError('required')">Example: 11:00 AM - 1:00 PM<br/>
            <b>You must fill out this field.</b>
            </mat-error>
      </mat-form-field>
      <br/><br/>
      <br/>
      <mat-form-field class="example-full-width">
        <mat-label for="preferred_schedule_request_text4">Alternative Schedule</mat-label>
        <textarea matInput id="preferred_schedule_request_text4" type="text" [formControl]="preferred_schedule_request_text4" required></textarea>
        <mat-hint>If we cannot offer your day(s) and time(s) listed above, please provide an alternative schedule.</mat-hint>
        <mat-error *ngIf="preferred_schedule_request_text4.hasError('required')">
        If we cannot offer your day(s) and time(s) listed above, please provide an alternative schedule.<br/>
        <b>You must fill out this field.</b></mat-error>
      </mat-form-field>
      <br/>
      <br/> <br/>
      <button style="margin-right:10px;" mat-raised-button color="warn" (click)="changeFormSection(1)">Go Back</button>
      <button style="margin-right:10px;" mat-raised-button color="primary" (click)="changeFormSection(3)">Continue</button> Page 2 of 4
</div>

<div *ngIf="formsection==3">
    <mat-form-field>
        <mat-label for="capacity">Max Capacity </mat-label>
        <input matInput id="capacity" type="number" [formControl]="capacity" required>
        <mat-hint>Must be greater than <b>0</b></mat-hint>
        <mat-error *ngIf="capacity.hasError('required')">Enter a number only. Must be greater than <b>0</b>.<br/>
            <b>You must fill out this field.</b></mat-error>
      </mat-form-field>
      <br/><br/>
      <mat-form-field>
        <mat-label for="minimum_capacity">Minimum Capacity </mat-label>
        <input matInput id="minimum_capacity" type="number" [formControl]="minimum_capacity" required>
        <mat-hint>Must be greater than <b>0</b></mat-hint>
        <mat-error *ngIf="minimum_capacity.hasError('required')">Enter a number only. Must be greater than <b>0</b>.<br/>
            <b>You must fill out this field.</b></mat-error>
      </mat-form-field>
      <br/><br/>
      <mat-form-field>
        <mat-label for="fees">Fees </mat-label>
        <input matInput id="fees" type="number" [formControl]="fees">
        <mat-hint>Enter a number only. If there is no fee, <b>leave blank</b>.</mat-hint>
      </mat-form-field>

      <br/><br/><br/>
      <mat-form-field class="example-full-width">
          <mat-label for="fees_description">Fees Description </mat-label>
          <textarea matInput id="fees_description" type="text" [formControl]="fees_description"></textarea>
        </mat-form-field>
        <br/>
        <br/> <br/>
        <button style="margin-right:10px;" mat-raised-button color="warn" (click)="changeFormSection(2)">Go Back</button>
        <button style="margin-right:10px;" mat-raised-button color="primary" (click)="changeFormSection(4)">Continue</button> Page 3 of 4
        <br/> <br/>        <br/> <br/>        <br/> <br/>
      </div>

<div *ngIf="formsection==4">
    <mat-form-field class="example-full-width">
        <mat-label for="location_type">My course will be...</mat-label>
        <mat-select matInput id="location_type" [formControl]="location_type" matNativeControl required>
          <mat-option value="In a classroom">In a classroom</mat-option>
          <mat-option value="In an inside space that is not a classroom">In an inside space that is not a classroom</mat-option>
          <mat-option value="In an outside space">In an outside space</mat-option>
          <mat-option value="Both in a classroom and in an outside space">Both in a classroom and in an outside space</mat-option>
          <mat-option value="An excursion, hike, or field trip in town">An excursion, hike, or field trip in town</mat-option>
          <mat-option value="An excursion, hike, or field trip out of town">An excursion, hike, or field trip out of town</mat-option>
          <mat-option value="An inside game course">An inside game course</mat-option>
          <mat-option value="An outside game course">An outside game course</mat-option>
          <mat-option value="On Zoom">On Zoom</mat-option>
        </mat-select>
        <mat-error *ngIf="location_type.hasError('required')">Select the best-fitting location type for your course.<br/>
          <b>You must make a selection.</b></mat-error>
        <mat-hint>Select the best-fitting location type for your course from the drop-down menu above.</mat-hint>
      </mat-form-field>
      <br/><br/>


      <mat-checkbox id="av_required" [formControl]="av_required">Is Audio/Visual equipment required to run your course? <b>(check if yes)</b>.</mat-checkbox>

    
<ng-container *ngIf="av_required.value">
  <br/>
  <mat-label>Select all the checkboxes for A/V equipment you will need for your course:</mat-label>
  <br/>
<mat-checkbox id="av_zoom" [formControl]="av_zoom">Zoom</mat-checkbox><br/>
<mat-checkbox id="av_powerpoint_pc" [formControl]="av_powerpoint_pc">PowerPoint</mat-checkbox><br/>
<mat-checkbox id="av_internet" [formControl]="av_internet">Internet</mat-checkbox><br/>
<mat-checkbox id="av_soundsystem" [formControl]="av_soundsystem">Sound System with Mic</mat-checkbox><br/>
<mat-checkbox id="av_whiteboard" [formControl]="av_whiteboard">Whiteboard</mat-checkbox><br/>
<mat-checkbox id="av_flipchart" [formControl]="av_flipchart">Flipchart</mat-checkbox><br/>
<mat-checkbox id="av_videos" [formControl]="av_videos">Video</mat-checkbox><br/>
<ng-container *ngIf="av_videos.value">

  <mat-label>What will be the video source?</mat-label>
  <br/>
  <mat-checkbox id="av_streaming" [formControl]="av_streaming">Streaming Service (e.g., YouTube, Amazon Prime, Netflix, other)</mat-checkbox><br/>
  <mat-checkbox id="av_vhs" [formControl]="av_vhs">VHS Videocassette</mat-checkbox><br/>
  <mat-checkbox id="av_flashdrive" [formControl]="av_flashdrive">Flash ("thumb") Drive</mat-checkbox><br/>
  <mat-checkbox id="av_smartphone" [formControl]="av_smartphone">Your Smartphone</mat-checkbox><br/>
  <mat-checkbox id="av_dvdplayer" [formControl]="av_dvdplayer">DVD</mat-checkbox><br/>
  <mat-checkbox id="av_bluray" [formControl]="av_bluray">Blu Ray</mat-checkbox><br/>
</ng-container>
<br/>
<mat-form-field class="half-width">
  <mat-label for="av_other">Other Audio/Visual Equipment</mat-label>
  <input matInput id="av_other" type="text" [formControl]="av_other">
</mat-form-field>
<br/>
<mat-form-field class="example-full-width">
  <mat-label for="av_sessions">For multi-day courses, list exactly which sessions need Audio/Visual equipment</mat-label>
  <textarea matInput id="av_sessions" type="text" [formControl]="av_sessions"></textarea>
  <mat-hint>If different equipment is needed on different days, please also mention that here.</mat-hint>
</mat-form-field>
<br/>
<br/>
<mat-form-field class="example-full-width" >
  <mat-label for="av_checklist">Any other comments regarding A/V needs?</mat-label>
  <textarea matInput id="av_checklist" type="text" [formControl]="av_checklist"></textarea>
</mat-form-field>

</ng-container>
      <br/> <br/>

      <mat-form-field class="example-full-width">
        <mat-label for="special_requests">Phone # and Any Special Requests</mat-label>
        <textarea rows="6" matInput id="special_requests" type="text" [formControl]="special_requests" required></textarea>
        <mat-hint>Enter your phone number and any special requests.</mat-hint>
        <mat-error *ngIf="special_requests.hasError('required')">Enter your phone number and any special requests.<br/>
            <b>You must fill out this field.</b></mat-error>
      </mat-form-field>
      <br/>
      <br/> <br/>
NOTE: After submitting this form, please email Denise a course photo to be displayed on the WILL website: <b><a href="mailto:denise@will.community">denise&#64;will.community</a></b>.
<br/> <br/>
It is against WILL policy to solicit or advertise 'for profit' services. As a Facilitator for WILL, you agree to not advertise in any way during a WILL function, session/class or via email.
<br/>
<br/> <br/>
      <button style="margin-right:10px;" mat-raised-button color="warn" (click)="changeFormSection(3)">Go Back</button>
      <button *ngIf="showSubmitButton" mat-raised-button color="primary" type="button" (click)="createCourse()">Submit Proposal</button>
      <button *ngIf="!showSubmitButton" mat-raised-button disabled >Please Wait...</button>&nbsp; Page 4 of 4
      <br/>
      <br/> <br/>      <br/>
      <br/> <br/>      <br/>
      <br/> <br/>      <br/>
      <br/> <br/>      <br/>
      <br/> <br/>
    </div>

  </div>