<div class="toolbarheader" *ngIf="lightningService.user['travel_request_active'] && lightningService.user['liability_waiver_active'] ">
    MY PROFILE
</div>
<div class="toolbarheader" *ngIf="!lightningService.user['travel_request_active'] || !lightningService.user['liability_waiver_active']">
   MY PROFILE, TRAVEL & LIABILITY FORMS
</div>
<br/>

Please keep your profile info updated. An asterisk<b>*</b> indicates a required field. When needed, the Travel Form & Liability Waiver will appear below.
<br/><br/>

<div *ngIf="picture.value">
  <img src="{{ picture.value }}" alt="picture" class="responsive"/>
  <br/><br/>
</div>

<b>{{ emailaddress }}</b>
<br/>
<br/>
<form #heroForm="ngForm">
    <mat-form-field class="example-full-width">
        <mat-label for="title">Name (First & Last)</mat-label>
        <input matInput id="title" type="text" [formControl]="title" required>
        <mat-hint><b>DO NOT</b> enter quotation marks, extra spaces, or any special characters that are not part of your legal name.</mat-hint>
    </mat-form-field>
    <br/><br/>


    <mat-form-field class="example-full-width">
        <mat-label for="address">Street Address </mat-label>
        <input matInput id="address" type="text" [formControl]="address" required>
    </mat-form-field>

    <br>

    <mat-form-field >
        <mat-label for="city">City </mat-label>
        <input matInput id="city" type="text" [formControl]="city" required>
    </mat-form-field>
    
    <mat-form-field >
        <mat-label for="state">State </mat-label>
        <input matInput id="state" type="text" [formControl]="state" required>
    </mat-form-field>
    
    <mat-form-field >
        <mat-label for="zip">Zip Code</mat-label>
        <input matInput id="zip" type="text" [formControl]="zip" required>
    </mat-form-field>
<br/>
    <mat-form-field>
        <mat-label for="phone">Phone Number </mat-label>
        <input matInput id="phone" type="text" [formControl]="phone" required>
    </mat-form-field>
    &nbsp;&nbsp;&nbsp;
<mat-checkbox [formControl]="contact_preference_sms">Check here to OK text messaging</mat-checkbox>

    <mat-divider></mat-divider>
    
    <mat-form-field class="example-full-width">
        <mat-label for="emergency_contact">Emergency Contact Info </mat-label>
        <input matInput id="emergency_contact" type="text" [formControl]="emergency_contact" required>
        <mat-hint>Please enter a name and phone number.</mat-hint>
    </mat-form-field>

    <br>
    <br>


   
    
    <mat-form-field class="example-full-width">
        <mat-label  for="picture">Profile Photo (optional)</mat-label >
        <input matInput id="picture" type="text" [formControl]="picture">
        <mat-hint>You may enter a <b>public link/URL</b> to an image.</mat-hint>
    </mat-form-field>
<br/><br/>
<div *ngIf="lightningService.user['member'] ">
    Membership status: <span style="color:green;font-weight:bold;">ACTIVE</span>
    <br/>
    Your membership expires on: <b>{{ member_expiration_date | date: 'mediumDate' }}</b>
</div>

<div *ngIf="!lightningService.user['member'] ">
    Membership status: <span style="color:red;font-weight:bold;">INACTIVE</span>
</div>

    <!--- begin TRF section -->
    <div *ngIf="!lightningService.user['travel_request_active'] ">
    <br/><br/>
    <div class="graybar">TRAVEL FORM 
    </div>
  <br/>



        <mat-divider></mat-divider>
        <br/>

        <i>Departure/Arrival: August 11, 2024 to August 10, 2025 in Personal Vehicle</i>
<br/>
<br/>	IF TRAVELING BY PRIVATE AUTOMOBILE YOU MUST AGREE TO THE FOLLOWING:
<br/>
Persons who use private automobiles on Western New Mexico University business, including WILL courses, must sign below certifying that there is an insurance policy in force which provides at least the following minimum coverage for the automobile used.
<br/>
<br/>
    1) $25,000 for personal injury to, or death of one person<br/>
    2) $50,000 for injury to, or death of two or more persons in one accident<br/>
    3) $10,000 for property damage
<br/>
<br/>
		<b>By typing my name below I hereby certify that the information stated above is currently true and complete and this coverage will continue through AUGUST 10, 2025, when this travel form will need to be renewed.</b>



        <mat-form-field class="example-full-width">
            <mat-label style="background-color:yellow;" for="travel_request_signature">TYPE YOUR NAME </mat-label>
            <input matInput id="travel_request_signature" type="text" [formControl]="travel_request_signature">
        </mat-form-field>
        <i>Enter your first and last names <u>exactly</u> as entered in the first question on this page, "<b>{{title.value}}</b>". Case-sensitive.</i>
    </div>





        <!--- begin liability waiver section -->
<div *ngIf="!lightningService.user['liability_waiver_active'] ">
        <br/><br/>
        <div class="graybar">LIABILITY WAIVER 
        </div>
        <br/>
        <mat-divider></mat-divider>
        <br/>
        
    <H3>WESTERN INSTITUTE FOR LIFELONG LEARNING (WILL) HIKES & EXCURSIONS: RELEASE OF LIABILITY, WAIVER OF CLAIMS, EXPRESS ASSUMPTION OF RISK AND INDEMNITY AGREEMENT</H3>
 
    <mat-accordion>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              CLICK HERE TO EXPAND AND REVIEW LIABILITY WAIVER
            </mat-panel-title>
            <mat-panel-description>
      &nbsp;
              <mat-icon class="icon-size">visibility</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

<p>Please read and be certain you understand the implications of signing this Express Assumption of Risk Associated and Indemnity with Hiking, Excursions, and Related Activities done as a member of WILL.
</p>
<p>
I do hereby affirm and acknowledge that I have been fully informed of the inherent hazards and risks associated with taking part in a WILL hike and/or excursion in which I may engage. Inherent hazards and risks include but are not limited to:
</p>

<h4>HIKES:</h4>
<ul>
    <li>Risk of injury from the activity and equipment utilized is significant including the potential for permanent disability and death.</li>
    <li>Falling, causing broken bones severe injuries to the head, neck and back which may result in severe impairment or even death.</li>
    <li>All "acts of nature," including, but not limited to, rock fall, inclement weather, thunder and lighting, severe and or varied wind, temperature, and other weather conditions.</li>
    <li>Risks associated with crossing, climbing, or down-climbing of rock, snow and/or ice.</li>
    <li>Risks associated with water crossings.</li>
    <li>Cold weather and heat related injuries and illness including, but not limited to, frostnip, heat exhaustion, heat stroke, sunburn, hypothermia and dehydration.</li>
    <li>Attack by or encounter with insects, reptiles, and/or animals.</li>
    <li>Accidents or illness occurring in remote places where there are no available medical facilities.</li>
    <li>Fatigue chill, and/or dizziness, which may diminish my/our reaction time and increase the risk of accident.</li>
    <li>My sense of balance, physical coordination, and ability to follow instructions.</li>
</ul>
<h4>EXCURSIONS:</h4>
<ul>
    <li>Risk of injury from the activity and equipment utilized may be present including the potential for permanent disability and death.</li>
    <li>Uneven surfaces that may result in falling, causing broken bones severe injuries to the head, neck and back which may result in severe impairment or even death.</li>
    <li>All "acts of nature," including, but not limited to, rock fall, inclement weather, thunder and lighting, severe and or varied wind, temperature, and other weather conditions.</li>
    <li>Risks, including bites, associated with encountering livestock and domestic animals such as horses, donkeys, dogs, cats, and any other live animals I may encounter while on a WILL excursion. </li>
    <li>Attack by or encounter with insects, reptiles, and/or wild animals.</li>
    <li>Accidents or illness occurring in remote places where there are no available medical facilities.</li>
    <li>Fatigue chill, and/or dizziness, which may diminish my/our reaction time and increase the risk of accident.</li>
    <li>My sense of balance, physical coordination, and ability to follow instructions.</li>
</ul>
<p>I understand the description of these risks is not complete and that unknown or unanticipated risks may result in Injury, illness, or death.  I also understand the following:</p>
<ul>
    <li>I cannot bring anyone with me on a WILL hike or excursion who is not a member of WILL.</li>
    <li>I must wear closed-toe shoes suitable for walking on uneven terrain (NO flip flops or sandals!) when attending WILL hikes and outdoor excursions. WILL reserves the right to deny participation to me if I do not wear footwear appropriate for the hike or excursion taking place. </li>
    <li>I must bring drinking water with me on all WILL hikes and outdoor excursions.  WILL reserves the right to deny participation to me if I do not bring drinking water with me to a hike or outdoor excursion. </li>
    <li>In the case that medical care, search and rescue, or any emergency services are needed for me I will be held financially responsible for any costs that occur as a result. </li>
</ul>


</mat-expansion-panel>
</mat-accordion>
<br/>
<p>
    In consideration for being permitted to participate in any way in WILL's hiking, excursions, and related activities, I hereby agree, acknowledge, and appreciate that:
</p>
<p>
    I RELEASE AND HOLD HARMLESS WITH RESPECT TO ANY AND ALL INJURY, DISABILITY, DEATH, or loss or damage to person or property, 
    WHETHER CAUSED BY NEGLIGENCE OR OTHERWISE, the following named persons or entities associated with WILL ("releasees") 
    including its officers, directors, employees, representatives, agents, and volunteers, from liability and responsibility 
    whatsoever and for any claims or causes of action that I, my estate, heirs, survivors, executors, or assignees may have for
     personal injury, property damage, or wrongful death arising from the above activities whether caused by active or passive 
     negligence of the releasees or otherwise. By executing this document, I agree to hold the releasees harmless and indemnify 
     them in conjunction with any injury, disability, death, or loss or damage to person or property that may occur as a result
      of engaging in the above activities. By entering into this Agreement, I am not relying on any oral or written representation, 
      or statements made by the releasees, other than what is set forth in this Agreement. This release shall be binding to the 
      fullest extent permitted by law. If any provision of this release is found to be unenforceable, the remaining terms shall 
      be enforceable.
</p>


<mat-checkbox id="liability_checkbox" [formControl]="liability_checkbox">I HAVE READ THIS RELEASE OF LIABILITY AND ASSUMPTION OF RISK AGREEMENT, AND I FULLY UNDERSTAND ITS TERMS, AND UNDERSTAND
    THAT I HAVE GIVEN UP LEGAL RIGHTS BY SIGNING IT, AND I SIGN IT FREELY AND VOLUNTARILY WITHOUT ANY INDUCEMENT.</mat-checkbox>
<div *ngIf="liability_checkbox.value">
<mat-form-field class="example-full-width">
    <mat-label style="background-color:yellow;" for="liability_waiver_signature">TYPE YOUR NAME </mat-label>
    <input matInput id="liability_waiver_signature" type="text" [formControl]="liability_waiver_signature">
</mat-form-field>
<i>Enter your first and last names <u>exactly</u> as entered in the first question on this page, "<b>{{title.value}}</b>". Case-sensitive.</i>
</div>
</div>
</form>
<br/>
<br/>

    <button mat-raised-button color="primary" (click)="updateProfile()">Update</button>
