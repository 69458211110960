
<div class="toolbarheader">
 USER PROFILE: {{ title.value }}
</div>
<br/>

<div *ngIf="picture.value">
  <img src="{{ picture.value }}" alt="picture" class="responsive"/>
  <br/><br/>
</div>


<b>{{ emailaddress.value }}</b>
<br/><br/>
<form #roleUpdateeForm="ngForm">

  <mat-form-field class="example-full-width">
    <mat-label for="title">Name </mat-label>
    <input matInput id="title" type="text" [formControl]="title">
    <mat-hint><b>DO NOT</b> enter quotation marks, extra spaces, or any special characters that are not part of their legal name.</mat-hint>
  </mat-form-field>
  <br/>
  <br/>


  <mat-form-field class="example-full-width">
    <mat-label for="description">Notes </mat-label>
    <textarea rows="3" matInput id="description" type="text" [formControl]="description"></textarea>
    <mat-hint>This field is visible to ADMINS only.</mat-hint>
  </mat-form-field>
  <br/>
  <br/>


  <mat-form-field class="example-form-field" *ngIf="member.value">
    <mat-label>Membership Expiration</mat-label>
    <input matInput type="text" id="start_date" [formControl]="start_date" [matDatepicker]="picker">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

<mat-checkbox [formControl]="member">Member</mat-checkbox>


  <br/>
<br/>
<div *ngIf="trf_active"><span style="color:green;font-weight:bold;"><mat-icon class="trf-icon">done</mat-icon>TRF completed </span></div>
<div *ngIf="!trf_active"><span style="color:red;font-weight:bold;"><mat-icon class="trf-icon">close</mat-icon> TRF NOT completed</span></div>

<div *ngIf="liability_active"><span style="color:green;font-weight:bold;"><mat-icon class="trf-icon">done</mat-icon>LIABILITY completed </span></div>
<div *ngIf="!liability_active"><span style="color:red;font-weight:bold;"><mat-icon class="trf-icon">close</mat-icon> LIABILITY NOT completed</span></div>
<br/>
  <mat-form-field class="third-width">
      <mat-label>Role</mat-label>
      <mat-select [formControl]="roleSelectFormControl" [(value)]="roleid_selected">
        <mat-option></mat-option>
        <mat-option *ngFor="let role_av of role_list" [value]="role_av['_id']">
          {{role_av['title']}}
        </mat-option>
      </mat-select>
    </mat-form-field>


  <mat-form-field class="example-full-width">
    <mat-label for="address">Street Address </mat-label>
    <input matInput id="address" type="text" [formControl]="address">
  </mat-form-field>

  <mat-form-field>
    <mat-label for="city">City </mat-label>
    <input matInput id="city" type="text" [formControl]="city">
  </mat-form-field>

  <mat-form-field>
    <mat-label for="state">State </mat-label>
    <input matInput id="state" type="text" [formControl]="state">
  </mat-form-field>

  <mat-form-field>
    <mat-label for="zip">Zip Code </mat-label>
    <input matInput id="zip" type="text" [formControl]="zip">
  </mat-form-field>
  <br/>

  <mat-form-field>
    <mat-label for="phone">Phone </mat-label>
    <input matInput id="phone" type="text" [formControl]="phone">
  </mat-form-field>
&nbsp;&nbsp;&nbsp;
<mat-checkbox [formControl]="contact_preference_sms">OK to text</mat-checkbox>

  <mat-form-field class="example-full-width">
    <mat-label for="emergency_contact">Emergency Contact Info </mat-label>
    <input matInput id="emergency_contact" type="text" [formControl]="emergency_contact">
  </mat-form-field>


      <mat-form-field class="example-full-width">
        <mat-label  for="picture">Profile Photo</mat-label >
        <input matInput id="picture" type="text" [formControl]="picture">
        <mat-hint>You may enter a <b>public link/URL</b> to an image.</mat-hint>
    </mat-form-field>
    <br/><br/>  <br/>
</form>

    <button *ngIf="lightningService.role.update_users" mat-raised-button color="primary" type="button" (click)="updateUser()">Update</button>
    <button *ngIf="lightningService.role.delete_users" style="margin-left:15px;" mat-raised-button color="warn" type="button" (click)="openDeleteConfirmDialog()">Delete User</button>

    <div *ngIf="lightningService.role.view_course_members">
    <div class="graybar">STUDENT COURSE LIST 
    </div>

    <div *ngFor="let coursemember of coursemembers">
      <table routerLink="/admin-course-detail/{{ coursemember['course_id'] }}">
        <tr>
          <th rowspan="3" *ngIf="coursemember['attending']"><mat-icon matListItemIcon style="color:green;" >check_circle</mat-icon>
          </th>
          <th rowspan="3" *ngIf="coursemember['standby']"><mat-icon matListItemIcon style="color:orange;" >schedule</mat-icon>
          </th>
          <td> <b>#{{ coursemember['course_order'] }}: {{ coursemember['course_title'] }}</b> - {{ coursemember['course_facilitator'] }}
          </td>
        </tr>
        <tr>
          <i>{{ coursemember['course_notes'] }}</i>
        </tr>
        <tr>
          <td *ngIf="coursemember['attending']">LOCATION: {{ coursemember['course_location'] }}
          </td>
          <td *ngIf="coursemember['standby']">This member is # {{ coursemember['student_order'] - coursemember['course_capacity'] }} on the waitlist
          </td>
        </tr>
      </table>
      </div>
    </div>