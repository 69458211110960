import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-admin-semester-detail',
  templateUrl: './admin-semester-detail.component.html',
  styleUrls: ['./admin-semester-detail.component.css']
})
export class AdminSemesterDetailComponent {
  constructor(public lightningService: LightningService, private route: ActivatedRoute, private router: Router) { }

  
  semesterid ="";
  public semester = {};
  public course_list = [];
  public semester_coursemember_count = 0;
  public distinct_user_count = 0;

  // load the role into the form 
  ngOnInit(): void {
    console.log('semester detail init');
    
    this.route.paramMap.subscribe((params) => {
      console.log('semester data params changed')
      this.semesterid = params.get('semesterid') || "";
      //this.role = this.productService.getProduct(this.id);
      this.getSemesterData();

    });

    

  }

  getSemesterData = async () => {
    if (localStorage.getItem("token") === null) {
      console.log('role data - no access token');
    }
    else
    {
      const response = await fetch(this.lightningService.apiUrl + '/admin/semesters/' + this.semesterid, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      console.log(result.semester); 
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
      if (result.semester) {
        console.log('found semester');
        console.log(result.semester['title'])
        console.log(result.semester['description'])
        this.semester = result.semester;
        this.title.setValue(result.semester['title']);
        this.description.setValue(result.semester['description']);

        this.courseDuration.controls['starts'].setValue(new Date(result.semester['starts']) );
        this.courseDuration.controls['ends'].setValue(new Date(result.semester['ends']) );

        this.earlySignupDuration.controls['early_signup_start'].setValue(new Date(result.semester['early_signup_start']) );
        this.earlySignupDuration.controls['early_signup_end'].setValue(new Date(result.semester['early_signup_end']) );
        this.signupDuration.controls['signup_start'].setValue(result.semester['signup_start']);
        this.signupDuration.controls['signup_end'].setValue(result.semester['signup_end']);
        this.year.setValue(result.semester['year']);
        this.visible.setValue(result.semester['visible']);
        this.active.setValue(result.semester['active']);
        this.upcoming.setValue(result.semester['upcoming']);
        this.signups_active.setValue(result.semester['signups_active']);
        this.max_course_signups.setValue(result.semester['max_course_signups']);
        this.max_course_signups_boolean.setValue(result.semester['max_course_signups_boolean']);
        this.semester_coursemember_count = result.semester_coursemember_count;
        this.distinct_user_count = result.distinct_user_count;

        this.getSemesterCourses();
      }
    }
  }

  getSemesterCourses = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/semesters/' + this.semesterid + '/courses/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
  
      this.course_list = result;
  
    }
  
  // datepicker defaults
  today = new Date();
  default_month = this.today.getMonth();
  default_year = this.today.getFullYear();

  title = new FormControl('');
  description = new FormControl('');

  // group all of these start/end datetimes for datepicker control
  // https://material.angular.io/components/datepicker/examples

  courseDuration = new FormGroup({
    starts: new FormControl(new Date(this.default_year, this.default_month, 13)),
    ends: new FormControl(new Date(this.default_year, this.default_month, 16)),
  });

  earlySignupDuration = new FormGroup({
    early_signup_start: new FormControl(new Date(this.default_year, this.default_month, 13)),
    early_signup_end: new FormControl(new Date(this.default_year, this.default_month, 16)),
  });

  signupDuration = new FormGroup({
    signup_start: new FormControl(new Date(this.default_year, this.default_month, 13)),
    signup_end: new FormControl(new Date(this.default_year, this.default_month, 16)),
  });

  liveDuration = new FormGroup({
    live_start: new FormControl(new Date(this.default_year, this.default_month, 13)),
    live_end: new FormControl(new Date(this.default_year, this.default_month, 16)),
  });

  year = new FormControl('');
  visible = new FormControl('');
  active = new FormControl('');
  upcoming = new FormControl('');
  signups_active = new FormControl('');
  max_course_signups = new FormControl('');
  max_course_signups_boolean = new FormControl('');

  updateSemester = async () => {
    console.log('update semester');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'_id':this.semesterid,
         'title':this.title.value,
        'description': this.description.value,
        'starts': this.courseDuration.controls['starts'].value,
        'ends': this.courseDuration.controls['ends'].value,
        'early_signup_start': this.earlySignupDuration.controls['early_signup_start'].value,
        'early_signup_end': this.earlySignupDuration.controls['early_signup_end'].value,
        'signup_start': this.signupDuration.controls['signup_start'].value,
        'signup_end': this.signupDuration.controls['signup_end'].value,
        'year': this.year.value,
        'visible': this.visible.value,
        'active': this.active.value,
        'upcoming': this.upcoming.value,
        'signups_active': this.signups_active.value,
        'max_course_signups': this.max_course_signups.value,
        'max_course_signups_boolean': this.max_course_signups_boolean.value
      
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/semesters/' + this.semesterid + '/update', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        console.log(data);
        this.router.navigate(['admin-semesters']);

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

}
