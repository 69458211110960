<div class="toolbarheader">CREATE A NEW CONCEPT</div>

<form #roleCreateForm="ngForm">

  <mat-form-field>
    <mat-label for="order">Course Number </mat-label>
    <input matInput id="order" type="number" [formControl]="order" required>
    <mat-hint>Enter a number only.</mat-hint>
    <mat-error *ngIf="order.hasError('required')">You must enter a number.</mat-error>
  </mat-form-field>
  <br/><br/>

    <mat-form-field class="example-full-width">
        <mat-label for="title">Title </mat-label>
        <input matInput id="title" type="text" [formControl]="title" required>
        <mat-hint>You must enter a title.</mat-hint>
        <mat-error *ngIf="title.hasError('required')">You must enter a title.</mat-error>
    </mat-form-field>

    <br/><br/>

<mat-form-field class="half-width">
  <mat-label for="status_number">Status Number </mat-label>
  <mat-select matInput id="status_number" [formControl]="status_number" type="number" matNativeControl>
    <mat-option value="1"><b>(1)</b> Idea with no facilitator</mat-option>
    <mat-option value="2"><b>(2)</b> Facilitator suggested</mat-option>
    <mat-option value="3"><b>(3)</b> Proposal submitted</mat-option>
    <mat-option value="4"><b>(4)</b> Facilitator response requested</mat-option>
    <mat-option value="5"><b>(5)</b> Transfer to semester page</mat-option>
    <mat-option value="6"><b>(6)</b> Final confirmation received</mat-option>
  </mat-select>
</mat-form-field>
<br/>
<mat-form-field class="third-width">
  <mat-label>Assignee Name</mat-label>
  <input matInput id="assignee" type="text" [formControl]="assignee">
</mat-form-field>

<br/>

<mat-form-field class="half-width">
  <mat-label>Facilitator ID</mat-label>
  <input matInput id="facilitator_id" type="text" [formControl]="facilitator_id">
  <mat-hint><a href="/admin-user-detail/{{facilitator_id.value}}">{{facilitator_name.value}}: View Profile</a></mat-hint>
</mat-form-field>
<br/><br/>

<mat-form-field>
  <mat-label>Facilitator Name</mat-label>
  <input matInput id="facilitator_name" type="text" [formControl]="facilitator_name">
  <mat-hint>Empty to auto-populate.</mat-hint>
</mat-form-field>

<br/><br/>

<mat-form-field class="example-full-width">
  <mat-label for="description">Course Description </mat-label>
  <textarea rows="3" matInput id="description" type="text" [formControl]="description"></textarea>
</mat-form-field>


<mat-form-field class="example-full-width">
  <mat-label for="facilitator_bio">Facilitator Bio </mat-label>
  <textarea rows="3" matInput id="facilitator_bio" type="text" [formControl]="facilitator_bio"></textarea>
</mat-form-field>

<mat-form-field class="half-width">
  <mat-label>Shepherd ID</mat-label>
  <input matInput id="shepherd_id" type="text" [formControl]="shepherd_id">
  <mat-hint><a href="/admin-user-detail/{{shepherd_id.value}}">: View Profile</a></mat-hint>
</mat-form-field>
<br/>
<br/>

<mat-form-field class="half-width">
  <mat-label>First Aid Provider ID</mat-label>
  <input matInput id="first_aid_user_id" type="text" [formControl]="first_aid_user_id">
  <mat-hint><a href="/admin-user-detail/{{first_aid_user_id.value}}">: View Profile</a></mat-hint>
</mat-form-field>
<br/>
<br/>


  <mat-form-field class="half-width">
    <mat-label for="subject_category">Subject Category </mat-label>
    <mat-select matInput id="subject_category" [formControl]="subject_category" matNativeControl>
      <mat-option value="Art, Music & Dance">[01] Art, Music & Dance</mat-option>
      <mat-option value="Literature, Film & Theater">[02] Literature, Film & Theater</mat-option>
      <mat-option value="History & Current Affairs">[03] History & Current Affairs</mat-option>
      <mat-option value="Science & Nature">[04] Science & Nature</mat-option>
      <mat-option value="Technology">[05] Technology</mat-option>
      <mat-option value="Health & Fitness">[06] Health & Fitness</mat-option>
      <mat-option value="Outdoor Experience">[07] Outdoor Experience</mat-option>
      <mat-option value="Out-of-Town Excursions">[08] Out-of-Town Excursions</mat-option>
      <mat-option value="Armchair Travel">[09] Armchair Travel</mat-option>
      <mat-option value="Self Discovery">[10] Self Discovery</mat-option>
      <mat-option value="Cooking & Food">[11] Cooking & Food</mat-option>
      <mat-option value="Games">[12] Games</mat-option>
      <mat-option value="How To/Everything Else">[13] How To/Everything Else</mat-option>
      <mat-option value="Local Issues & Adventures">[14] Local Issues & Adventures</mat-option>
    </mat-select>
  </mat-form-field>
  

  <mat-form-field class="example-full-width">
    <mat-label for="semester_request">Semester Requested </mat-label>
    <input matInput id="semester_request" type="text" [formControl]="semester_request">
    <mat-hint>This field does NOT show on the public course page.</mat-hint>
</mat-form-field>
<br/><br/>

<mat-form-field class="example-full-width">
  <mat-label for="preferred_schedule_request_text">Preferred Schedule Requested </mat-label>
  <textarea rows="3" matInput id="preferred_schedule_request_text" type="text" [formControl]="preferred_schedule_request_text"></textarea>
  <mat-hint>This field does NOT show on the public course page.</mat-hint>
</mat-form-field>
<br/><br/>

<mat-form-field class="example-full-width">
  <mat-label for="notes">Sessions/Date/Time </mat-label>
  <textarea matInput id="notes" type="text" [formControl]="notes"></textarea>
</mat-form-field>

<mat-form-field class="example-full-width">
  <mat-label for="facilitator_shepherd_requested">Admin Notes</mat-label>
  <textarea matInput id="facilitator_shepherd_requested" type="text" [formControl]="facilitator_shepherd_requested"></textarea>
  <mat-hint>This field does NOT show on the public course page.
  </mat-hint>
</mat-form-field>
<br/><br/>

<mat-form-field>
  <mat-label for="capacity">Max Capacity </mat-label>
  <input matInput id="capacity" type="number" [formControl]="capacity">
  <mat-hint>Enter max number only.</mat-hint>
</mat-form-field>


  <mat-form-field>
    <mat-label for="minimum_capacity">Minimum Capacity </mat-label>
    <input matInput id="minimum_capacity" type="number" [formControl]="minimum_capacity">
    <mat-hint>Enter minimum number only.</mat-hint>
  </mat-form-field>
  <br/><br/>


  <mat-form-field>
    <mat-label for="fees">Fees </mat-label>
    <input matInput id="fees" type="number" [formControl]="fees">
    <mat-hint>Enter a number only. If there is no fee, <b>leave blank</b>.</mat-hint>
  </mat-form-field>
  <br/><br/>
<mat-form-field class="example-full-width">
    <mat-label for="fees_description">Fees Description </mat-label>
    <textarea matInput id="fees_description" type="text" [formControl]="fees_description"></textarea>
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label for="location">Location </mat-label>
    <input matInput id="location" type="text" [formControl]="location">
</mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label for="location_type">Location Type </mat-label>
    <mat-select matInput id="location_type" [formControl]="location_type" matNativeControl>
      <mat-option value="In a classroom">In a classroom</mat-option>
      <mat-option value="In an inside space that is not a classroom">In an inside space that is not a classroom</mat-option>
      <mat-option value="In an outside space">In an outside space</mat-option>
      <mat-option value="Both in a classroom and in an outside space">Both in a classroom and in an outside space</mat-option>
      <mat-option value="An excursion, hike, or field trip in town">An excursion, hike, or field trip in town</mat-option>
      <mat-option value="An excursion, hike, or field trip out of town">An excursion, hike, or field trip out of town</mat-option>
      <mat-option value="An inside game course">An inside game course</mat-option>
      <mat-option value="An outside game course">An outside game course</mat-option>
      <mat-option value="On Zoom">On Zoom</mat-option>
    </mat-select>
    <mat-hint>This field does NOT show on the public course page.</mat-hint>
  </mat-form-field>
  <br/><br/>

  <mat-checkbox id="outdoor_field_trip" [formControl]="outdoor_field_trip">Outdoor</mat-checkbox>
  <mat-form-field class="example-full-width" *ngIf="outdoor_field_trip.value">
    <mat-label for="outdoor">Outdoor ADMIN NOTES </mat-label>
    <input matInput id="outdoor" type="text" [formControl]="outdoor">
    <mat-hint>This field does NOT show on the public course page.</mat-hint>
  </mat-form-field>
  <br/><br/>


  <mat-checkbox id="av_required" [formControl]="av_required">AV Required</mat-checkbox>
  <mat-form-field class="example-full-width" *ngIf="av_required.value">
    <mat-label for="av_checklist">AV Checklist </mat-label>
    <textarea matInput id="av_checklist" type="text" [formControl]="av_checklist"></textarea>
    <mat-hint>EX: computer, adapter (Apple/PC), projector, mic system, white board, DVD player, internet, PowerPoint, jump drive, tech support, which sessions need A/V</mat-hint>
  </mat-form-field>
<br/><br/>


<mat-form-field class="example-full-width">
  <mat-label for="special_requests">Phone # and Special Requests </mat-label>
  <input matInput id="special_requests" type="text" [formControl]="special_requests">
  <mat-hint>This field does NOT show on the public course page.</mat-hint>
</mat-form-field>
<br/><br/>

    


      <mat-form-field class="example-full-width">
        <mat-label for="photo">Photo </mat-label>
        <input matInput id="photo" type="text" [formControl]="photo">
        <mat-hint>https://lh3.googleusercontent.com/d/<b>[file ID]</b> --- <i>200px max height</i></mat-hint>
      </mat-form-field>
      <br/><br/>


</form>

    <button mat-raised-button color="primary" type="button" (click)="createCourse()">Create</button>
