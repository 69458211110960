import { Component } from '@angular/core';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent {
  constructor(private lightningService: LightningService) { }
  public user_list = [];
  public member_list = [];
  public inactive_list = [];
  public user_count = 0;
  public member_count = 0;
  public searchText ="";

  public show_user_list = true;
  public show_member_list = false;
  public show_inactive_list = false;

  do_show_member_list(){
    this.show_user_list = false;
    this.show_member_list = true;
    this.show_inactive_list = false;
  }
  do_show_inactive_list(){
    this.show_user_list = false;
    this.show_member_list = false;
    this.show_inactive_list = true;
  }
  do_show_user_list(){
    this.show_user_list = true;
    this.show_member_list = false;
    this.show_inactive_list = false;
  }

  ngOnInit(): void {
    console.log('profile init');
    //this.title.setValue(this.lightningService.user['title']);
    this.getUserData();

  }

  getUserData = async () => {
  const response = await fetch(this.lightningService.apiUrl + '/users/', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    const result = await response.json();
    console.log(result);
    if (result.status == "401")
    {
      this.lightningService.requestLogin();
    }

    this.user_list = result.users;
    this.user_count = result.user_count;
    this.member_count = result.member_count;

    // seperate user list into members and inactives
    for (let i = 0; i < this.user_list.length; i++) {
      if (this.user_list[i]['member'] )
      {
        this.member_list.push(this.user_list[i]);
      }
      else
      {
        this.inactive_list.push(this.user_list[i]);
      }
    }

  }
  

}
