<div class="toolbarheader">MY COURSE LIST</div>

  <br/>
This is a list of all the courses you have signed up for this semester. The location for a course will only appear when you are enrolled with a confirmed seat; otherwise, you will see your position on the waitlist. 
Use the <a routerLink="/assets/images/WNMU_map.pdf" target="_blank">[ WNMU Campus Map (PDF) ]</a> to assist you once your class location is revealed below. 
Click on a listed course to view the full course description. If you need to cancel a course from your course list, please immediately email <b><a href="mailto:registrar@will.community">registrar&#64;will.community</a></b> with the course # and 
title to inform us.

<br/>
<br/>
<mat-icon class="enrolled_icon">check_circle</mat-icon> = Enrolled with confirmed seat
<br/>
<mat-icon class="waitlisted_icon">schedule</mat-icon> = Waitlisted
<br/>
<mat-icon class="pending_icon">pending</mat-icon> = Please wait one minute and reload this page to view enrollment status.
<br/>
<br/>

<mat-divider></mat-divider>
<br/>

<div *ngFor="let coursemember of coursemembers">
  <table routerLink="/course-detail/{{ coursemember['course_id'] }}">
    <tr>
      <th rowspan="3" *ngIf="coursemember['attending']"><mat-icon matListItemIcon style="color:green;" >check_circle</mat-icon>
      </th>
      <th rowspan="3" *ngIf="coursemember['standby']"><mat-icon matListItemIcon style="color:orange;" >schedule</mat-icon>
      </th>
      <th rowspan="3" *ngIf="!coursemember['standby'] && !coursemember['attending']"><mat-icon matListItemIcon style="color:rgba(158, 158, 158, 0.664);" >pending</mat-icon>
      </th>
      <td> <b>#{{ coursemember['course_order'] }}: {{ coursemember['course_title'] }}</b> - {{ coursemember['course_facilitator'] }}
      </td>
    </tr>
    <tr>
      <i>{{ coursemember['course_notes'] }}</i>
    </tr>
    <tr>
      <td *ngIf="coursemember['attending']">LOCATION: {{ coursemember['course_location'] }}
      </td>
      <td *ngIf="coursemember['standby']">You are # {{ coursemember['student_order'] - coursemember['course_capacity'] }} on the waitlist
      </td>
    </tr>
  </table>
  </div>


  <div *ngIf="!lightningService.user['member']">
    <br/>
    You must have an active <a routerLink="/pages/membership">WILL Membership</a> before signing up for courses.
    </div>
  <div *ngIf="!coursemembers.length">
<b>You are not currently signed up for any courses.</b>
<br/><br/>
  </div>


  <div *ngIf="!shepherd_courses.length"><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
  </div>

<div *ngIf="shepherd_courses.length">
  <br/><br/>
  <div class="graybar">COURSES YOU'RE SHEPHERDING 
  </div>
<br/>
  <div *ngIf="!lightningService.user['travel_request_active'] ">You must complete your
  <a routerLink="/profile">Travel Form on your Profile page</a> before being able to view your Shepherd Course List.
  </div>
  <div *ngIf="lightningService.user['travel_request_active'] ">
    <div>
    This is a list of all the courses you are <b>shepherding</b> for this semester. Please visit the 
    <b><a routerLink="/pages/shepherds" target="_blank">Shepherds page</a></b> to access all of your online shepherd resources.
    Click on a listed course to view the full course description.
    </div>
<br/>
<mat-divider></mat-divider>
<br/>
<br/>
    <div class="shepherd_list">
    <mat-list *ngFor="let shepherd_course of shepherd_courses" routerLink="/course-detail/{{ shepherd_course['_id'] }}" class="courselink">
      <mat-list-item lines="6">
        <span matListItemTitle> <b>#{{ shepherd_course['order'] }}: {{ shepherd_course['title'] }}</b></span>
        <span matListItemLine><i>{{ shepherd_course['notes'] }}</i></span>
        <span matListItemLine>FACILITATOR: {{ shepherd_course['facilitator_name'] }}</span>
        <span matListItemLine>LOCATION: {{ shepherd_course['location'] }}</span>
        <span matListItemLine *ngIf="shepherd_course['fees']">FEE: ${{ shepherd_course['fees'] }}</span>
        <span matListItemLine>There are <b>{{ shepherd_course['signups'] }} students</b> signed up. Course Limit: {{ shepherd_course['capacity'] }}</span>
      </mat-list-item>
  <br/>
    </mat-list>
    </div>
  </div>
</div>
<br/><br/><br/><br/>