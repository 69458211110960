import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DeleteDialogData } from '../delete-dialog-data';

@Component({
  selector: 'app-delete-confirm-dialog',
  templateUrl: './delete-confirm-dialog.component.html',
  styleUrls: ['./delete-confirm-dialog.component.css']
})
export class DeleteConfirmDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteDialogData,
                private dialogRef: MatDialogRef<DeleteConfirmDialogComponent>) {
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    yesButtonClicked(): void {
        this.dialogRef.close(true);
    }

}
