import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';

import { LightningService } from '../lightning.service';
import { User } from '../user';

import { GenericResponseDialogComponent } from '../generic-response-dialog/generic-response-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
  constructor(public lightningService: LightningService, public dialog: MatDialog, private route: ActivatedRoute, private router: Router) { }

  profile_user_json = {};
  public member = false;

  user: User = {
    id: 0,
    title: 'Profile User',
    address: '',
    emailvalidated: false,
    travel_request_active: false,
    liability_waiver_active: false,
    member: false
  };


  title = new FormControl('');
  address = new FormControl('');
  city = new FormControl('');
  state = new FormControl('');
  zip = new FormControl('');
  emergency_contact = new FormControl('');
  phone = new FormControl('');
  picture = new FormControl('');
  contact_preference_sms  = new FormControl('');
  emailaddress = "";
  liability_waiver_signature = new FormControl('');
  travel_request_signature = new FormControl('');
  liability_checkbox = new FormControl('');
  member_expiration_date = "";

  ngOnInit(): void {
    console.log('profile init');
    //this.title.setValue(this.lightningService.user['title']);
    this.getUserData();

  }

  getUserData = async () => {
    if (localStorage.getItem("token") === null) {
      console.log('profile - no access token');
    }
    else
    {
      const response = await fetch(this.lightningService.apiUrl + '/users/data', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      console.log(result.user); 
      console.log(result.status); 
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
      if (result.user) {
        console.log('found user');
        this.user = result.user;
        this.title.setValue(result.user['title']);
        this.address.setValue(result.user['address']);
        this.city.setValue(result.user['city']);
        this.state.setValue(result.user['state']);
        this.zip.setValue(result.user['zip']);
        this.emergency_contact.setValue(result.user['emergency_contact']);
        this.phone.setValue(result.user['phone']);
        this.picture.setValue(result.user['picture']);
        this.contact_preference_sms.setValue(result.user['contact_preference_sms']);
        this.member = result.user['member'];
        this.emailaddress = result.user['emailaddress'];
        this.member_expiration_date = result.user['member_expiration_date'];

      }
    }
  }


  updateProfile = async () => {
    console.log('update profile');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    const location = '13.56.127.211' // hardcoding for now

    var clean_title = "";
    if (this.title.value)
    {
      clean_title = this.title.value.replace(/[^a-z/-/./(/)/']/gmi, " ").replace(/\s+/g, " ");
    }
    console.log(clean_title);

    // check to make sure all of the required fields have values
    if (this.title.value=="" ||
    this.address.value=="" ||
    this.city.value=="" ||
    this.state.value=="" ||
    this.zip.value=="" ||
    this.emergency_contact.value=="" ||
    this.phone.value=="" 
    ){
      //alert ('You missed a required field. Please fill out all fields with an asterisk (*).');
      this.dialog.open(GenericResponseDialogComponent, {
        data: {
          response_message: 'We need your contact information. Please fill out all fields with an asterisk (*).',
          response_title: 'Required Field'
        },
      });
      return;
    }

    // we need to check to see if the travel request from has been filled out previously.
    // If it hasn't then we need to check tosee ifthe title and trf_signature match


    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'title':clean_title,
        'address':this.address.value,
        'city':this.city.value,
        'state' :this.state.value,
        'zip':this.zip.value,
        'emergency_contact' :this.emergency_contact.value,
        'phone':this.phone.value,
        'picture':this.picture.value,
        'contact_preference_sms':this.contact_preference_sms.value,
        'travel_request_signature': this.travel_request_signature.value,
        'liability_waiver_signature': this.liability_waiver_signature.value
      })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/users/profile/update', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        // TODO - update localstorage with the new profile data
        //const result = await data.json();
        console.log(data);
        //localStorage.setItem('user', JSON.stringify(result.user));
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }

        this.dialog.open(GenericResponseDialogComponent, {
          data: {
            response_message: data['response_message'],
            response_title: data['response_title']
          },
        });

        localStorage.setItem('usertitle', this.title.value || "Lightning User!");

        // we want to do an update at some point, later.
        // doing this here, results in overwriting the actual form data
        this.lightningService.getUserData();
        this.router.navigate(['' ]);
        //this.profile_user_json = result.user;
        return data;
    //} catch (e) {
    //    return e;
    //} 

  }
}
