import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-admin-role-create',
  templateUrl: './admin-role-create.component.html',
  styleUrls: ['./admin-role-create.component.css']
})
export class AdminRoleCreateComponent {
  constructor(private lightningService: LightningService, private router: Router) { }

  title = new FormControl('');
  description = new FormControl('');
  create_concepts = new FormControl('');
  create_eg = new FormControl('');
  create_lunchlearn = new FormControl('');
  create_news = new FormControl('');
  create_programs = new FormControl('');
  create_roles = new FormControl('');
  create_semesters = new FormControl('');
  create_travelgroup = new FormControl('');
  create_users = new FormControl('');
  create_volunteer = new FormControl('');
  create_card = new FormControl('');
  delete_concepts = new FormControl('');
  delete_course_members = new FormControl('');
  delete_eg = new FormControl('');
  delete_lunchlearn = new FormControl('');
  delete_news = new FormControl('');
  delete_programs = new FormControl('');
  delete_roles = new FormControl('');
  delete_travelgroup = new FormControl('');
  delete_users = new FormControl('');
  delete_volunteer = new FormControl('');
  delete_card = new FormControl('');
  update_concepts = new FormControl('');
  update_course_members = new FormControl('');
  update_eg = new FormControl('');
  update_lunchlearn = new FormControl('');
  update_news = new FormControl('');
  update_programs = new FormControl('');
  update_roles = new FormControl('');
  update_semesters = new FormControl('');
  update_travelgroup = new FormControl('');
  update_users = new FormControl('');
  update_volunteer = new FormControl('');
  update_pages = new FormControl('');
  update_card = new FormControl('');
  view_admin_concepts = new FormControl('');
  view_admin_courses = new FormControl('');
  view_admin_interface = new FormControl('');
  view_admin_roles_interface = new FormControl('');
  view_admin_semesters = new FormControl('');
  view_admin_users_interface = new FormControl('');
  view_course_members = new FormControl('');
  view_shepherd_resources = new FormControl('');

  createRole = async () => {
    console.log('create role');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'title':this.title.value,
        'description': this.description.value,
        'create_concepts': this.create_concepts.value,
        'create_eg': this.create_eg.value,
        'create_lunchlearn': this.create_lunchlearn.value,
        'create_news': this.create_news.value,
        'create_programs': this.create_programs.value,
        'create_roles': this.create_roles.value,
        'create_semesters': this.create_semesters.value,
        'create_travelgroup': this.create_travelgroup.value,
        'create_users': this.create_users.value,
        'create_volunteer': this.create_volunteer.value,
        'create_card': this.create_card.value,
        'delete_concepts': this.delete_concepts.value,
        'delete_course_members': this.delete_course_members.value,
        'delete_eg': this.delete_eg.value,
        'delete_lunchlearn': this.delete_lunchlearn.value,
        'delete_news': this.delete_news.value,
        'delete_programs': this.delete_programs.value,
        'delete_roles': this.delete_roles.value,
        'delete_travelgroup': this.delete_travelgroup.value,
        'delete_users': this.delete_users.value,
        'delete_volunteer': this.delete_volunteer.value,
        'delete_card': this.create_card.value,
        'update_concepts': this.update_concepts.value,
        'update_course_members': this.update_course_members.value,
        'update_eg': this.update_eg.value,
        'update_lunchlearn': this.update_lunchlearn.value,
        'update_news': this.update_news.value,
        'update_programs': this.update_programs.value,
        'update_roles': this.update_roles.value,
        'update_semesters': this.update_semesters.value,
        'update_travelgroup': this.update_travelgroup.value,
        'update_users': this.update_users.value,
        'update_volunteer': this.update_volunteer.value,
        'update_pages': this.update_pages.value,
        'update_card': this.create_card.value,
        'view_admin_concepts': this.view_admin_concepts.value,
        'view_admin_courses': this.view_admin_courses.value,
        'view_admin_interface': this.view_admin_interface.value,
        'view_admin_roles_interface': this.view_admin_roles_interface.value,
        'view_admin_semesters': this.view_admin_semesters.value,
        'view_admin_users_interface': this.view_admin_users_interface.value,
        'view_course_members': this.view_course_members.value,
        'view_shepherd_resources': this.view_shepherd_resources.value
      
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/roles/create', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        console.log(data);

        this.router.navigate(['admin-roles']);

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

}
