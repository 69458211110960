import { Component, inject } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { User } from '../app/user';
import { AuthChallenge } from './authchallenge';

import { LightningService } from '../app/lightning.service';
import { LoginDialogComponent } from '../app/login-dialog/login-dialog.component'
import { LoginDialogData } from '../app/login-dialog-data';

import {MatDialog, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

import { Role } from './role';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'my-app';
  authchall: AuthChallenge | undefined;
  qrcodesrc = 'bla';
  loginComplete = false;
  loginButtonVisible = false;
  public logoutButtonVisible = false;
  public userTitle = "Logged Out"

  role: Role = {
    id: 0,
    title: 'No Role Assigned',
    create_concepts: false,
    create_eg: false,
    create_lunchlearn: false,
    create_news: false,
    create_programs: false,
    create_roles: false,
    create_semesters: false,
    create_travelgroup: false,
    create_users: false,
    create_volunteer: false,
    create_card: false,
    delete_concepts: false,
    delete_course_members: false,
    delete_eg: false,
    delete_lunchlearn: false,
    delete_news: false,
    delete_programs: false,
    delete_roles: false,
    delete_travelgroup: false,
    delete_users: false,
    delete_volunteer: false,
    delete_card: false,
    update_concepts: false,
    update_course_members: false,
    update_eg: false,
    update_lunchlearn: false,
    update_news: false,
    update_programs: false,
    update_roles: false,
    update_semesters: false,
    update_travelgroup: false,
    update_users: false,
    update_volunteer: false,
    update_pages: false,
    update_card: false,
    view_admin_concepts: false,
    view_admin_courses: false,
    view_admin_interface: false,
    view_admin_roles_interface: false,
    view_admin_semesters: false,
    view_admin_users_interface: false,
    view_course_members: false,
    view_shepherd_resources: false
  };

  constructor(public lightningService: LightningService, private dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem("token") === null) {
      this.loginButtonVisible = true;
      this.logoutButtonVisible = false;
    }
    else
    {
      this.loginButtonVisible = false;
      this.logoutButtonVisible = true;
      // tell the lightning service to get user data
      //this.lightningService.getUserData(); // does not work.  

      // We can sometimes be in a state where a user logs into the site, does not log out, and returns to the site in the future after the toekn is expired.
      // It will prompt them to log in.
      // If they choose not to log in, they still have an invalid token in local storage

      // we need to keep track of if a login was successful.
      // If not, we need to clear local storage.

      
    }

    if (localStorage.getItem("usertitle") === null) {
      console.log('usertitle not found in localstorage');
    }
    else 
    {
      this.userTitle = localStorage.getItem("usertitle") || "";
    }

    if (localStorage.getItem("role") === null) {
      console.log('role not found in localstorage');
    }
    else 
    {
      var role_str = localStorage.getItem("role") || "";
      console.log('roleObject: ', JSON.parse(role_str));
      this.role = JSON.parse(role_str);
    }

    // Subscribe to the login user in lightning service to know if we need to run auth again
    this.lightningService.login_user.subscribe( (value) => {
      console.log('lightning service requesting re-authentication');

      this.loginButtonVisible = true;
      this.logoutButtonVisible = false;

      // Only do this if there is an old token in localstorage
      if (localStorage.getItem('token'))
      {
        
        console.log('found an old token');
        this.loginChallenge();
      }
      

    });

    // subscribe to the user in lightning service for changes 
    this.lightningService.sub_user.subscribe( (value) => {
      console.log('lightning service user changed');
      console.log(this.lightningService.user);
      console.log(this.lightningService.role);
      this.user = this.lightningService.user;
      this.userTitle = this.lightningService.user.title;
      //this.role = this.lightningService.role;
      //console.log(this.role.view_admin_interface.valueOf());
    });
    // subscribe to UI changes
    this.lightningService.showCloseDialog.subscribe( (value) => {
      console.log('show close dialog');
      this.closeDialog();
      this.loginButtonVisible = false;
      this.logoutButtonVisible = true;
    });

    this.dialog.afterAllClosed.subscribe(data=>{
      console.log("data returned from mat-dialog-close is ",data);
      this.lightningService.signinActive = false;
  })

    this.lightningService.getUserData();
  }

  public user = this.lightningService.user;

  //user: User = {
  //  id: 0,
  //  title: 'Test User',
  //  address: '',
  //  emailvalidated: false,
  //};

  isLoggedIn() 
  {
    //console.log('is logged in');
    if (this.lightningService.signInComplete)
    {
      this.user = this.lightningService.user;
      return true;
    } 
    else
    {
      return false;
    }
  }

  loginChallenge() {
    console.log('App Component Login pressed');

    this.lightningService.loginChallenge()
    .subscribe(authchall => {
      this.authchall = authchall;
      this.qrcodesrc = 'https://will.community/generate_qr/' + authchall.lnurl;

      // open the login dialog
      this.dialog.open(LoginDialogComponent, {
        data: {
          lnurl: this.authchall,
          qrcodesrc: this.qrcodesrc
        },
      });
    });
  }

  

  closeDialog () {
    this.dialog.closeAll();
  }

  logout () {
    this.lightningService.doLogout();
    this.loginButtonVisible = true;
    this.logoutButtonVisible = false;
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    this.router.navigate(['']);
  }

  private breakpointObserver = inject(BreakpointObserver);

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Tablet)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    isXsmall: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.XSmall)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    isSmall: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Small)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    isMedium: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Medium)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    isLarge: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Large)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    isXLarge: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.XLarge)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


  
  
}

