import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';

import { LightningService } from '../lightning.service';


@Component({
  selector: 'app-admin-semester-create',
  templateUrl: './admin-semester-create.component.html',
  styleUrls: ['./admin-semester-create.component.css']
})
export class AdminSemesterCreateComponent {
  constructor(private lightningService: LightningService, private router: Router) { }

  // datepicker defaults
  today = new Date();
  default_month = this.today.getMonth();
  default_year = this.today.getFullYear();

  title = new FormControl('');
  description = new FormControl('');

  // group all of these start/end datetimes for datepicker control
  // https://material.angular.io/components/datepicker/examples

  courseDuration = new FormGroup({
    starts: new FormControl(new Date(this.default_year, this.default_month, 13)),
    ends: new FormControl(new Date(this.default_year, this.default_month, 16)),
  });

  earlySignupDuration = new FormGroup({
    early_signup_start: new FormControl(new Date(this.default_year, this.default_month, 13)),
    early_signup_end: new FormControl(new Date(this.default_year, this.default_month, 16)),
  });

  signupDuration = new FormGroup({
    signup_start: new FormControl(new Date(this.default_year, this.default_month, 13)),
    signup_end: new FormControl(new Date(this.default_year, this.default_month, 16)),
  });

  liveDuration = new FormGroup({
    live_start: new FormControl(new Date(this.default_year, this.default_month, 13)),
    live_end: new FormControl(new Date(this.default_year, this.default_month, 16)),
  });

  year = new FormControl('');
  visible = new FormControl('');
  active = new FormControl('');
  upcoming = new FormControl('');
  signups_active = new FormControl('');
  max_course_signups = new FormControl('');
  max_course_signups_boolean = new FormControl('');

  createSemester = async () => {
    console.log('create semester');
    console.log(localStorage.getItem("token"));

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'title':this.title.value,
        'description': this.description.value,
        'starts': this.courseDuration.controls['starts'].value,
        'ends': this.courseDuration.controls['ends'].value,
        'early_signup_start': this.earlySignupDuration.controls['early_signup_start'].value,
        'early_signup_end': this.earlySignupDuration.controls['early_signup_end'].value,
        'signup_start': this.signupDuration.controls['signup_start'].value,
        'signup_end': this.signupDuration.controls['signup_end'].value,
        'year': this.year.value,
        'visible': this.visible.value,
        'active': this.active.value,
        'upcoming': this.upcoming.value,
        'signups_active': this.signups_active.value,
        'max_course_signups': this.max_course_signups.value,
        'max_course_signups_boolean': this.max_course_signups_boolean.value
      
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/semesters/create', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        console.log(data);

        this.router.navigate(['admin-semesters']);

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

}
