import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomepageComponent } from './homepage/homepage.component'
import { CourseListComponent } from './course-list/course-list.component'
import { AdminComponent } from './admin/admin.component'
import { AdminUsersComponent } from './admin-users/admin-users.component'
import { AdminUserDetailComponent } from './admin-user-detail/admin-user-detail.component'
import { AdminRolesComponent } from './admin-roles/admin-roles.component'
import { AdminRoleCreateComponent } from './admin-role-create/admin-role-create.component'
import { AdminRoleDetailComponent } from './admin-role-detail/admin-role-detail.component'
import { AdminCoursesComponent } from './admin-courses/admin-courses.component'
import { AdminCourseCreateComponent } from './admin-course-create/admin-course-create.component'
import { AdminCourseDetailComponent } from './admin-course-detail/admin-course-detail.component'
import { AdminSemestersComponent } from './admin-semesters/admin-semesters.component'
import { AdminSemesterCreateComponent } from './admin-semester-create/admin-semester-create.component'
import { AdminSemesterDetailComponent } from './admin-semester-detail/admin-semester-detail.component'
import { AdminPageCreateComponent } from './admin-page-create/admin-page-create.component'
import { AdminPageDetailComponent } from './admin-page-detail/admin-page-detail.component'
import { AdminPageUpdateComponent } from './admin-page-update/admin-page-update.component'
import { ProfileComponent } from './profile/profile.component'
import { EnrollmentListComponent } from './enrollment-list/enrollment-list.component'
import { SemesterSignupComponent } from './semester-signup/semester-signup.component'
import { CourseDetailComponent } from './course-detail/course-detail.component'
import { AdminCardCreateComponent } from './admin-card-create/admin-card-create.component'
import { AdminCardDetailComponent } from './admin-card-detail/admin-card-detail.component'
import { NewsPageComponent } from './news-page/news-page.component'
import { LunchlearnPageComponent } from './lunchlearn-page/lunchlearn-page.component'
import { ProgramsPageComponent } from './programs-page/programs-page.component'
import { EnthusiastgroupsPageComponent } from './enthusiastgroups-page/enthusiastgroups-page.component'
import { VolunteerPageComponent } from './volunteer-page/volunteer-page.component'
import { CourseProposalWizardComponent } from './course-proposal-wizard/course-proposal-wizard.component'

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'course-list', component: CourseListComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'admin-users', component: AdminUsersComponent },
  { path: 'admin-user-detail/:userid', component: AdminUserDetailComponent },
  { path: 'admin-roles', component: AdminRolesComponent },
  { path: 'admin-roles-create', component: AdminRoleCreateComponent },
  { path: 'admin-roles-detail/:roleid', component: AdminRoleDetailComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'enrollment-list', component: EnrollmentListComponent },
  { path: 'admin-courses', component: AdminCoursesComponent },
  { path: 'admin-course-create', component: AdminCourseCreateComponent },
  { path: 'admin-course-detail/:courseid', component: AdminCourseDetailComponent },
  { path: 'admin-semesters', component: AdminSemestersComponent },
  { path: 'admin-semester-create', component: AdminSemesterCreateComponent },
  { path: 'admin-semester-detail/:semesterid', component: AdminSemesterDetailComponent },
  { path: 'admin-page-create', component: AdminPageCreateComponent },
  { path: 'pages/:slugify_url', component: AdminPageDetailComponent },
  { path: 'admin-page-update/:slugify_url', component: AdminPageUpdateComponent },
  { path: 'semester-signup', component: SemesterSignupComponent },
  { path: 'course-detail/:courseid', component: CourseDetailComponent },
  { path: 'admin-card-create/:slugify_url', component: AdminCardCreateComponent },
  { path: 'admin-card-detail/:cardid', component: AdminCardDetailComponent },
  { path: 'news', component: NewsPageComponent },
  { path: 'lunchlearn', component: LunchlearnPageComponent },
  { path: 'programs', component: ProgramsPageComponent },
  { path: 'enthusiastgroups', component: EnthusiastgroupsPageComponent },
  { path: 'volunteer', component: VolunteerPageComponent },
  { path: 'course-proposal-wizard', component: CourseProposalWizardComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
