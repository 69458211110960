<div class="toolbarheader">{{ title }}
  <span class="spacer"></span>
  <button *ngIf="lightningService.role.update_pages" class="button-style" routerLink="/admin-page-update/{{ slugify_url }}"><mat-icon>edit</mat-icon></button>
</div>

<img *ngIf="header_image" src="{{header_image}}" alt="banner" width="100%">
<br/><br/>



<div [innerHtml]="html_safe_text | safe: 'html'"></div>

<mat-card class="example-card"  *ngFor="let a_card of cards">  
  <img *ngIf="a_card['header_image']" class="card-image" mat-card-image src="{{ a_card['header_image'] }}" alt="Card Image">
    <mat-card-header>
      <mat-card-title class="card-title">{{ a_card['title'] }}</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    
    <mat-card-content>
      <p [innerHtml]="a_card['html_safe_text'] | safe: 'html'">

      </p>
    </mat-card-content>
    <mat-card-actions>
      <button matTooltip="Order #{{ a_card['order'] }}" mat-button *ngIf="lightningService.role.update_card" routerLink="/admin-card-detail/{{ a_card['_id'] }}">EDIT</button>
    </mat-card-actions>
  </mat-card>


