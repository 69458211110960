import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { DeleteConfirmDialogComponent } from '../delete-confirm-dialog/delete-confirm-dialog.component'
import { GenericErrorDialogComponent } from '../generic-error-dialog/generic-error-dialog.component';
import { LightningService } from '../lightning.service';
import {MatDialog, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';

@Component({
  selector: 'app-admin-user-detail',
  templateUrl: './admin-user-detail.component.html',
  styleUrls: ['./admin-user-detail.component.css']
})
export class AdminUserDetailComponent {
  constructor(public lightningService: LightningService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) { }

  userid ="";
  public user = {};
  public role_list = [];
  public roleid_selected = "";
  public trf_active = false;
  public liability_active = false;

  // load the role into the form 
  ngOnInit(): void {
    console.log('user detail init');
    
    this.route.paramMap.subscribe((params) => {
      console.log('user data params changed')
      this.userid = params.get('userid') || "";
      //this.role = this.productService.getProduct(this.id);
      this.getUserData();
      
     

    });
  }

  getUserData = async () => {
    if (localStorage.getItem("token") === null) {
      console.log('user data - no access token');
    }
    else
    {
      const response = await fetch(this.lightningService.apiUrl + '/admin/users/' + this.userid, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      console.log(result.user); 
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
      if (result.user) {
        console.log('found user');
        console.log(result.user['title'])
        console.log(result.user['description'])
        this.user = result.user;
        this.title.setValue(result.user['title']);
        this.description.setValue(result.user['description']);

        this.address.setValue(result.user['address']);
        this.city.setValue(result.user['city']);
        this.state.setValue(result.user['state']);
        this.zip.setValue(result.user['zip']);
        this.contact_preference_sms.setValue(result.user['contact_preference_sms']);
        this.emailaddress.setValue(result.user['emailaddress']);
        this.emergency_contact.setValue(result.user['emergency_contact']);
        this.member.setValue(result.user['member']);
        this.roleid.setValue(result.user['roleid']);
        this.roleid_selected = result.user['roleid'];
        this.trf_active = result.user['travel_request_active'];
        this.liability_active = result.user['liability_waiver_active'];

        this.phone.setValue(result.user['phone']);
        this.picture.setValue(result.user['picture']);

        //this.membershipDuration.controls['start_date'].setValue(new Date(result.user['member_start_date']) );
        //this.membershipDuration.controls['end_date'].setValue(new Date(result.user['member_expiration_date']) );

       //ORIGINAL CODE: this.start_date.setValue(new Date(result.user['member_start_date']) );
       
        //  check to see if the date is blank.  
        // If so keep it blank
        // If false, use the date supplied
        if (!result.user['member_start_date'])
        {
          console.log('member_start_date NOT found');
          this.start_date.setValue( null );
        }
        else{
          console.log('member_start_date found');
          this.start_date.setValue(new Date(result.user['member_start_date']) );
        }




        this.getRoles();
        this.getEnrollmentListData();

      }
    }
  }

  getRoles = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/roles/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      // we have to check for permission first
      if (this.lightningService.role.view_admin_roles_interface)
        {

        
      const result = await response.json();
      console.log(result);
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
 
      this.role_list = result;
      this.roleSelectFormControl.setValue(this.roleid_selected);
    }
  
    }


  // datepicker defaults
  today = new Date();
  default_month = this.today.getMonth();
  default_year = this.today.getFullYear();


  title = new FormControl('');
  description = new FormControl('');
  address = new FormControl('');
  city = new FormControl('');
  state = new FormControl('');
  zip = new FormControl('');
  contact_preference_sms = new FormControl('');
  emailaddress = new FormControl('');
  emergency_contact = new FormControl('');
  member = new FormControl('');
  roleid = new FormControl('');
  roleSelectFormControl = new FormControl('');
  role_assigned = new FormControl('');
  phone = new FormControl('');
  picture = new FormControl('');

  //membershipDuration = new FormGroup({
  //  start_date: new FormControl(new Date(this.default_year, this.default_month, 13)),
  //  end_date: new FormControl(new Date(this.default_year, this.default_month, 16)),
  //});

  start_date= new FormControl(new Date(this.default_year, this.default_month, 13));

  coursemembers = [];

  getEnrollmentListData = async () => {
    if (localStorage.getItem("token") === null) {
      console.log('enrollment data - no access token');
    }
    else
    {
      const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      };

      // we have to check for permission first
      if (this.lightningService.role.view_course_members)
        {

      const response = await fetch(this.lightningService.apiUrl + '/admin/users/' + this.userid + '/enrollment_list', settings);
      const result = await response.json();
      console.log(result);
      console.log(result.coursemembers); 
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
      if (result.coursemembers) {
        console.log('found coursemembers');

        this.coursemembers = result.coursemembers;


      }
    }
    }
  }

  
  updateUser = async () => {
    console.log('update user');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'_id':this.userid,
         'title':this.title.value,
         'emailaddress': this.emailaddress.value,
        'description': this.description.value,
        'address': this.address.value,
        'city': this.city.value,
        'state': this.state.value,
        'zip': this.zip.value,
        'contact_preference_sms': this.contact_preference_sms.value,
        'emergency_contact': this.emergency_contact.value,
        'member': this.member.value,
        'roleid': this.roleSelectFormControl.value,
        'phone': this.phone.value,
        'picture': this.picture.value,
        'member_start_date': this.start_date.value,
        'member_expiration_date': this.start_date.value,
      
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/users/' + this.userid + '/update', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        this.router.navigate(['admin-users']);

        console.log(data);

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

  openDeleteConfirmDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {title: "Confirm Deletion", description: "Are you sure you want to delete this user? This action cannot be undone."},
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('The dialog was closed');
      //this.animal = result;
      if (data) {
        console.log('Confirm was pressed');
        this.deleteUser();
      }
    });
  }

  deleteUser = async () => {
    console.log('delete user');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'_id':this.userid})
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/users/' + this.userid + '/delete', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?
        this.router.navigate(['admin-users']);
        
        console.log(data);

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }




}
