import { Component } from '@angular/core';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-lunchlearn-page',
  templateUrl: './lunchlearn-page.component.html',
  styleUrls: ['./lunchlearn-page.component.css']
})
export class LunchlearnPageComponent {
  constructor(public lightningService: LightningService) { }
  slugify_url ="lunchandlearn";
  title = "";
  html_safe_text = "";
  header_image = "";

  public page = {};
  public cards = [];

  // load the page into the form 
  ngOnInit(): void {
    console.log('lunchlearn init');
    
      this.getPageData();
      this.getCardData();

  }

  getPageData = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/pages/' + this.slugify_url);
    const result = await response.json();
    console.log(result);
    console.log(result.page); 
    if (result.page) {
      console.log('found page');
      console.log(result.page['title'])
      this.page = result.page;
      this.title = result.page['title']
      this.html_safe_text = result.page['html_safe_text']
      this.header_image = result.page['header_image']
    }
  }


  getCardData = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/cards/' + this.slugify_url);
    const result = await response.json();
    console.log(result);
    console.log(result.cards); 
    
    if (result.cards) {
      console.log('found cards');
      this.cards = result.cards;
    }
  }


}
