import { Component } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import { LightningService } from '../lightning.service';

@Component({
  selector: 'app-admin-page-detail',
  templateUrl: './admin-page-detail.component.html',
  styleUrls: ['./admin-page-detail.component.css']
})
export class AdminPageDetailComponent {
  constructor(public lightningService: LightningService, private route: ActivatedRoute) { }

  public slugify_url ="";
  title = "";
  html_safe_text = "";
  header_image = "";

  public page = {};
  public cards = [];

  // load the page into the form 
  ngOnInit(): void {
    console.log('semester detail init');
    
    this.route.paramMap.subscribe((params) => {
      console.log('semester data params changed')
      this.slugify_url = params.get('slugify_url') || "";
      //this.role = this.productService.getProduct(this.id);
      this.getPageData();
      this.getCardData();

    });
  }

  getPageData = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/pages/' + this.slugify_url);
    const result = await response.json();
    console.log(result);
    console.log(result.page); 
    if (result.status == "401")
    {
      this.lightningService.requestLogin();
    }
    
    if (result.page) {
      console.log('found page');
      console.log(result.page['title'])
      this.page = result.page;
      this.title = result.page['title']
      this.html_safe_text = result.page['html_safe_text']
      this.header_image = result.page['header_image']
    }
  }

  getCardData = async () => {
    const response = await fetch(this.lightningService.apiUrl + '/cards/' + this.slugify_url);
    const result = await response.json();
    console.log(result);
    console.log(result.cards); 
    if (result.status == "401")
    {
      this.lightningService.requestLogin();
    }
    
    if (result.cards) {
      console.log('found cards');
      this.cards = result.cards;
    }
  }


}
