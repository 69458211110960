import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';

import { LightningService } from '../lightning.service';
import {MatDialog, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import { GenericResponseDialogComponent } from '../generic-response-dialog/generic-response-dialog.component';

@Component({
  selector: 'app-admin-page-update',
  templateUrl: './admin-page-update.component.html',
  styleUrls: ['./admin-page-update.component.css']
})
export class AdminPageUpdateComponent {
  constructor(private lightningService: LightningService, private route: ActivatedRoute, private router: Router, public dialog: MatDialog) { }

  pageid ="";
  public page_slugify_url = "";
  public page = {};

  // load the role into the form 
  ngOnInit(): void {
    console.log('page update init');
    
    this.route.paramMap.subscribe((params) => {
      console.log('role data params changed')
      this.page_slugify_url = params.get('slugify_url') || "";
      //this.role = this.productService.getProduct(this.id);
      this.getRoleData();

    });

    

  }

  getRoleData = async () => {
    if (localStorage.getItem("token") === null) {
      console.log('role data - no access token');
    }
    else
    {
      const response = await fetch(this.lightningService.apiUrl + '/pages/' + this.page_slugify_url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const result = await response.json();
      console.log(result);
      console.log(result.page); 
      if (result.status == "401")
      {
        this.lightningService.requestLogin();
      }
      if (result.page) {
        console.log('found page');
        console.log(result.page['title'])
        this.page = result.page;

        this.pageid = result.page['_id'];

        this.title.setValue(result.page['title']);
        this.slugify_uri.setValue(result.page['slugify_uri']);

        this.html_safe_text.setValue(result.page['html_safe_text']);
        this.header_image.setValue(result.page['header_image']);

      }
    }
  }

  
  
  title = new FormControl('');
  slugify_uri = new FormControl('');
  html_safe_text = new FormControl('');
  header_image = new FormControl('');

  updatePage = async () => {
    console.log('update page');
    console.log(localStorage.getItem("token"));

    //const location = window.location.hostname; // this works for live
    //const location = '13.56.127.211' // hardcoding for now
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({'_id':this.pageid,
         'title':this.title.value,
        'slugify_uri': this.slugify_uri.value,
        'html_safe_text': this.html_safe_text.value,
        'header_image': this.header_image.value
                            })
    };
    //try {
        const fetchResponse = await fetch(this.lightningService.apiUrl + '/admin/pages/' + this.page_slugify_url + '/update', settings);
        console.log(fetchResponse);
        const data = await fetchResponse.json();
        if (data.status == "401")
        {
          this.lightningService.requestLogin();
        }
        // we dont have to do anything special here.  redirect?

        this.dialog.open(GenericResponseDialogComponent, {
          data: {
            response_message: 'Your changes have been saved.', 
            response_title: 'Success!'
          },
        });
        console.log(data);

        return data;
    //} catch (e) {
    //    return e;
    //} 

  }

}
